import { getApiKeyPropertyName } from "@novel/shared/utils/appConstants";
import { apiPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";

import { getParsedQuery } from "@novel/shared/utils/getParsedQuery";
import { RestReq } from "@novel/shared/utils/restReq";
import { ClientApplication, createApp } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { getOrgDomainFromGlobals } from "@vendor-portal/redux/utils/authRedirectUtils";

import { getFromLocalStorage, setInLocalStorage } from "../../../shared/utils/localStorageUtils";

export async function getAppBridgeInstance(): Promise<ClientApplication<any>> {
    const encodedHost = getParsedQuery().host;
    if (!encodedHost || typeof encodedHost !== "string") {
        throw new Error("host not given in query params, needed for redirect");
    }

    return createApp({
        apiKey: await ensurePublicApiKey(),
        host: encodedHost,
    });
}

export async function getAppBridgeSessionToken(): Promise<string> {
    return getSessionToken(await getAppBridgeInstance());
}

const untypedRequestMaker = new RestReq(apiPrefixedHostWithProtocol);
export async function ensurePublicApiKey(
    orgDomain: string | undefined = getOrgDomainFromGlobals(),
): Promise<string> {
    if (!orgDomain) {
        throw new Error("shop not given in globals, needed for redirect");
    }

    const localStorageKey = getApiKeyPropertyName(orgDomain);
    const apiKeyFromLocalStorage = getFromLocalStorage(localStorageKey);
    if (apiKeyFromLocalStorage) {
        return apiKeyFromLocalStorage;
    } else {
        const result = await untypedRequestMaker.getReq("/api/public/app-public-api-key", {
            query: {
                shop: orgDomain,
            },
        });
        if (result.type === "error") {
            throw result.error;
        }

        const { appPublicApiKey } = result.body as { appPublicApiKey: string };
        setInLocalStorage(localStorageKey, appPublicApiKey);

        return appPublicApiKey;
    }
}
