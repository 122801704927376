import { IPushGroup } from "@novel/shared/interfaces/PushGroup";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ILocationCampaignsReducerState {
    isLoading: boolean;
    data: IPushGroup[];
}

const initialState: ILocationCampaignsReducerState = {
    isLoading: true,
    data: [],
};

export const locationCampaignsReducer: NovelVendorSubReducer<ILocationCampaignsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_LOCATION_CAMPAIGNS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_LOCATION_CAMPAIGNS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_LOCATION_CAMPAIGNS": {
            return {
                ...state,
                data: action.payload.locationCampaigns,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
