import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadGatedExperiences =
    (): NovelThunkVendorAction<
        "LOADING_GATED_EXPERIENCES" | "ERROR_LOADING_GATED_EXPERIENCES" | "LOADED_GATED_EXPERIENCES"
    > =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/gated-experience"),
            () =>
                dispatch({
                    type: "LOADING_GATED_EXPERIENCES",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_GATED_EXPERIENCES",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (fullGatesRes) =>
                dispatch({
                    type: "LOADED_GATED_EXPERIENCES",
                    payload: {
                        fullGatesRes,
                    },
                }),
        );
