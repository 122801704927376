import { IPaginatedBlockchainTransactionWithAddressesAndCustomers } from "@novel/shared/interfaces/BlockchainTransaction";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IIndividualTokenTransactionsReducerState
    extends IPaginatedBlockchainTransactionWithAddressesAndCustomers {
    isLoading: boolean;
    contractAddress: string | null;
    tokenId: string | null;
}

const initialState: IIndividualTokenTransactionsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
    contractAddress: null,
    tokenId: null,
};

export const individualTokenTransactionsReducer: NovelVendorSubReducer<
    IIndividualTokenTransactionsReducerState
> = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_INDIVIDUAL_TOKEN_TRANSACTIONS": {
            return {
                ...state,
                contractAddress: action.payload.contractAddress,
                tokenId: action.payload.tokenId,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_INDIVIDUAL_TOKEN_TRANSACTIONS": {
            return {
                ...state,
                contractAddress: null,
                tokenId: null,
                isLoading: false,
            };
        }

        case "LOADED_INDIVIDUAL_TOKEN_TRANSACTIONS": {
            return {
                ...state,
                totalCount: action.payload.paginatedTransactions.totalCount,
                pageSize: action.payload.paginatedTransactions.pageSize,
                pageNumber: action.payload.paginatedTransactions.pageNumber,
                totalPages: action.payload.paginatedTransactions.totalPages,
                contractAddress: action.payload.contractAddress,
                tokenId: action.payload.tokenId,
                data: action.payload.paginatedTransactions.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
