import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadSiteUrls =
    (): NovelThunkVendorAction<"LOADING_SITEURLS" | "ERROR_LOADING_SITEURLS" | "LOADED_SITEURLS"> =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/site-urls"),
            () =>
                dispatch({
                    type: "LOADING_SITEURLS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_SITEURLS",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (siteUrlsRes) =>
                dispatch({
                    type: "LOADED_SITEURLS",
                    payload: {
                        siteUrlsRes,
                    },
                }),
        );
