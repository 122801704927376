import { IProductsRes } from "@novel/shared/interfaces/Product";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IProductsReducerState extends IProductsRes {
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: IProductsReducerState = {
    isLoading: true,
    initialLoadHasBeenTriggered: false,
    data: [],
};

export const productsReducer: NovelVendorSubReducer<IProductsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_PRODUCTS": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_PRODUCTS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_PRODUCTS": {
            return {
                ...state,
                data: action.payload.productsRes.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
