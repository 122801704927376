import {
    CryptoOrFiatCurrencyCode,
    OtherStoreCreditCurrencyCode,
    StoreCreditCurrencyCode,
} from "@novel/shared/utils/formatMoney";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import { setOrgDomainGlobals } from "@vendor-portal/redux/utils/authRedirectUtils";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
import { useCallback } from "react";
import { INovelState } from "..";
import { IOrganization } from "@novel/shared/interfaces/Organization";
import {
    IntegrationVendorEnum,
    integrationsUsingPoints,
} from "@novel/shared/interfaces/Integration";
import { CurrencyCode } from "@novel/shared/__generated__/graphql";
import { IRewardsConfig } from "@novel/shared/interfaces/RewardsConfig";

export interface IVendorOrganizationsReducerState {
    isLoading: boolean;
    isLoadingIntegrations: boolean;
    billingTimeUpdating: boolean;
    billingRetriesConfigUpdating: boolean;
    errorMessage: string | null;
    activeOrganizationId: string | null;
    organizations: IOrganization[];
}

const initialState: IVendorOrganizationsReducerState = {
    isLoading: true,
    isLoadingIntegrations: false,
    billingTimeUpdating: false,
    billingRetriesConfigUpdating: false,
    errorMessage: null,
    activeOrganizationId: null,
    organizations: [],
};

export const vendorOrganizationsReducer: NovelVendorSubReducer<IVendorOrganizationsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_USER": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "LOADED_USER": {
            const newOrganizations = (action.payload.user?.vendorOrgRoles || []).map(
                (orgRole) => orgRole.org,
            );

            // sync shop globals for active organizatiion shopify domain
            if (action.payload.activeOrganizationId) {
                const matchingOrg = newOrganizations.find(
                    ({ id }) => id === action.payload.activeOrganizationId,
                );
                const matchingOrgMyShopify = matchingOrg?.shopifyShopData?.myShopifyDomain;
                const domain = matchingOrgMyShopify || matchingOrg?.orgDomain;
                setOrgDomainGlobals(domain || null);
            }

            return {
                ...state,
                isLoading: false,
                activeOrganizationId: action.payload.activeOrganizationId || null,
                organizations: newOrganizations,
            };
        }

        default:
            return state;
    }
};

export function organizationByIdSelector(
    state: INovelState,
    orgId: string | null,
): IOrganization | null {
    if (!orgId) {
        return null;
    }

    return state.vendorOrganizations.organizations.find(({ id }) => id === orgId) || null;
}

export function activeOrganizationSelector(state: INovelState): IOrganization | null {
    return organizationByIdSelector(state, state.vendorOrganizations.activeOrganizationId);
}

export function useActiveOrganizationSelector(): IOrganization | null {
    return useNovelVendorSelector(activeOrganizationSelector);
}

export function useActiveOrganizationSubSelector<TSelectedWithinOrg>(
    orgSelector: (org: IOrganization | null) => TSelectedWithinOrg | null,
): TSelectedWithinOrg | null {
    const finalOrgSelector = useCallback(
        (state: INovelState): TSelectedWithinOrg | null => {
            const activeOrg = activeOrganizationSelector(state);
            return orgSelector(activeOrg);
        },
        [orgSelector],
    );

    return useNovelVendorSelector(finalOrgSelector);
}

export function useActiveOrganizationHasPointSystem(): boolean {
    const usesPoints = useCallback((state: INovelState) => {
        return state.integrations.activeOrganizationIntegrations.some((integration) =>
            integrationsUsingPoints.includes(integration.integrationId as IntegrationVendorEnum),
        );
    }, []);

    return useNovelVendorSelector(usesPoints);
}

export function useActiveOrganizationIdSelector(): string | null {
    return useActiveOrganizationSubSelector((org) => org?.id || null);
}

export function useActiveOrgRewardsConfigSelector(): IRewardsConfig | null {
    return useActiveOrganizationSubSelector((org) => org?.rewardsConfig || null);
}

export function useActiveOrgHasNonPassholderRewardsEnabledSelector(): boolean {
    return !!useActiveOrganizationSubSelector(
        (org) => !!org?.rewardsConfig?.nonPassholderRewardsEnabled,
    );
}

export function useActiveOrganizationMyShopifyDomain(): string | null {
    return useActiveOrganizationSubSelector((org) => org?.shopifyShopData?.myShopifyDomain || null);
}

export function useActiveOrganizationCurrencySelector(): CryptoOrFiatCurrencyCode | null {
    return useActiveOrganizationSubSelector((org) => org?.shopifyShopData?.currency || null);
}

const fallbackArr: StoreCreditCurrencyCode[] = [CurrencyCode.Usd];
export function useActiveOrganizationEnabledCurrenciesSelector(): StoreCreditCurrencyCode[] {
    const shopCurrency = useActiveOrganizationCurrencySelector();

    if (useActiveOrganizationHasPointSystem()) {
        return [OtherStoreCreditCurrencyCode.Pts as OtherStoreCreditCurrencyCode];
    }

    return (
        useActiveOrganizationSubSelector((org) => {
            // sorting to put shop currency first
            const enabledCurrencies = (
                (org?.shopifyShopData?.enabled_presentment_currencies || [
                    ...(shopCurrency ? [shopCurrency] : []),
                ]) as CryptoOrFiatCurrencyCode[]
            ).slice();
            if (shopCurrency && enabledCurrencies.includes(shopCurrency)) {
                enabledCurrencies.sort((a, b) =>
                    a === shopCurrency ? -1 : b === shopCurrency ? 1 : 0,
                );
            }
            const result = enabledCurrencies.filter(
                (currency) => currency !== null && currency !== undefined,
            ) as StoreCreditCurrencyCode[];

            if (!result || result.length === 0) {
                return fallbackArr;
            }

            return result;
        }) || fallbackArr
    );
}
