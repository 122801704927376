import * as React from "react";
function PassSetupIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10305_2111)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.00306 2.67407C3.00307 1.22038 4.13468 0.00268555 5.57792 0.00268555H12.2174C12.2184 0.00268555 12.2195 0.00268859 12.2205 0.00269467L14.4994 0.0026978C15.9426 0.00269791 17.0742 1.22039 17.0742 2.67409L17.0743 3.90384C17.0745 3.91007 17.0746 3.91634 17.0746 3.92263V17.331C17.0746 18.7847 15.943 20.0024 14.4998 20.0024L7.85987 20.0024C7.85873 20.0024 7.85759 20.0024 7.85645 20.0024H5.57792C4.13468 20.0024 3.00306 18.7847 3.00306 17.331V16.1021C3.00281 16.0956 3.00269 16.089 3.00269 16.0824L3.00306 2.67408C3.00306 2.67408 3.00306 2.67407 3.00306 2.67407ZM4.00306 2.6741V17.331C4.00306 18.2758 4.72934 19.0024 5.57792 19.0024H14.4998C15.3483 19.0024 16.0746 18.2758 16.0746 17.331L16.0742 2.67409C16.0742 1.72933 15.348 1.0027 14.4994 1.0027L12.2174 1.00269H5.57792C4.72934 1.00269 4.00306 1.72932 4.00306 2.67408V2.6741Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_10305_2111">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>;
}
export default PassSetupIcon;