import { ISiteUrlsRes } from "@novel/shared/interfaces/Gate";

import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ISiteUrlsReducerState extends ISiteUrlsRes {
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: ISiteUrlsReducerState = {
    isLoading: true,
    initialLoadHasBeenTriggered: false,
    data: [],
};

export const siteUrlsReducer: NovelVendorSubReducer<ISiteUrlsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_SITEURLS": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_SITEURLS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_SITEURLS": {
            return {
                ...state,
                data: action.payload.siteUrlsRes.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
