import { IOrganizationIntegrationConfig } from "@novel/shared/interfaces/Integration";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IOrganizationsIntegrationsReducerState {
    isLoadingIntegrations: boolean;
    initialLoadHasBeenTriggered: boolean;
    activeOrganizationIntegrations: IOrganizationIntegrationConfig[];
}

const initialState: IOrganizationsIntegrationsReducerState = {
    isLoadingIntegrations: true,
    initialLoadHasBeenTriggered: false,
    activeOrganizationIntegrations: [],
};

export const organizationsIntegrationsReducer: NovelVendorSubReducer<
    IOrganizationsIntegrationsReducerState
> = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_INTEGRATIONS": {
            return {
                ...state,
                isLoadingIntegrations: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_INTEGRATIONS": {
            return {
                ...state,
                isLoadingIntegrations: false,
            };
        }

        case "LOADED_INTEGRATIONS": {
            return {
                ...state,
                isLoadingIntegrations: false,
                activeOrganizationIntegrations: action.payload.integrations,
            };
        }

        default:
            return state;
    }
};
