import { NovelSortDirection } from "@novel/shared/interfaces/shared/PaginationResult";
import { ParsedQs } from "qs";

import { SurfaceableError } from "./SurfaceableError";

export interface ISerializedPaginatedQuery extends ParsedQs {
    pageSize: string;
    pageNumber: string;
    orderBy?: {
        [key: string]: NovelSortDirection;
    };
    filterLike?: {
        [key: string]: string;
    };
    filterBefore?: {
        [key: string]: string;
    };
    filterAfter?: {
        [key: string]: string;
    };
    filterEqual?: {
        [key: string]: any;
    };
}

export interface IDeserializedPaginatedQuery {
    pageSize: number;
    pageNumber: number;
    orderBy?: {
        [key: string]: NovelSortDirection;
    };
    filterLike?: {
        [key: string]: string;
    };
    filterBefore?: {
        [key: string]: number;
    };
    filterAfter?: {
        [key: string]: number;
    };
    filterEqual?: {
        [key: string]: any;
    };
}

export interface ILoadPaginatedAction {
    queryParams?: IDeserializedPaginatedQuery;
}

export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 30;

export const MIN_PAGE_NUMBER = 0;
export const MIN_PAGE_SIZE = 1;
export const MAX_PAGE_SIZE = 250;

export function deserializePaginatedQuery(
    queryObject: ISerializedPaginatedQuery,
): IDeserializedPaginatedQuery {
    let pageSize =
        typeof queryObject.pageSize === "string"
            ? +queryObject.pageSize.trim() || DEFAULT_PAGE_SIZE
            : DEFAULT_PAGE_SIZE;
    let pageNumber =
        typeof queryObject.pageNumber === "string"
            ? +queryObject.pageNumber.trim() || DEFAULT_PAGE_NUMBER
            : DEFAULT_PAGE_NUMBER;

    // If after the normalization, we get NaN, Infinite or a value lesser than 0, we need to set the fallback value
    if (!Number.isFinite(pageSize) || pageSize <= 0) pageSize = DEFAULT_PAGE_SIZE;

    // If after the normalization, we get NaN, Infinite or a value lesser than 1, we need to set the fallback value
    if (!Number.isFinite(pageNumber) || pageNumber < 0) pageNumber = 0;

    const { orderBy, filterLike, filterBefore, filterAfter, filterEqual } = queryObject;

    if (pageNumber < MIN_PAGE_NUMBER) {
        throw new SurfaceableError(
            `page number provided ${pageNumber} is under min of ${MIN_PAGE_NUMBER}`,
        );
    }

    if (pageSize < MIN_PAGE_SIZE) {
        throw new SurfaceableError(
            `page size provided ${pageSize} is under min of ${MIN_PAGE_SIZE}`,
        );
    }

    if (pageSize > MAX_PAGE_SIZE) {
        throw new SurfaceableError(
            `page size provided ${pageSize} is over max of ${MAX_PAGE_SIZE}`,
        );
    }

    return {
        pageSize,
        pageNumber,
        filterLike: filterLike
            ? Object.keys(filterLike).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["filterLike"]>, field) => {
                      const value = filterLike[field];
                      const finalValue = value && String(value).trim();
                      if (finalValue) {
                          accum[field] = finalValue;
                      }
                      return accum;
                  },
                  {},
              )
            : undefined,
        orderBy: orderBy
            ? Object.keys(orderBy).reduce(
                  (accum: NonNullable<IDeserializedPaginatedQuery["orderBy"]>, field) => {
                      const value = orderBy[field];
                      accum[field] = value || "DESC";
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterBefore: filterBefore
            ? Object.keys(filterBefore).reduce(
                  (accum: NonNullable<IDeserializedPaginatedQuery["filterBefore"]>, field) => {
                      const value = filterBefore[field];
                      accum[field] = Number(value || 0) || 0;
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterAfter: filterAfter
            ? Object.keys(filterAfter).reduce(
                  (accum: NonNullable<IDeserializedPaginatedQuery["filterAfter"]>, field) => {
                      const value = filterAfter[field];
                      accum[field] = Number(value || 0) || 0;
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterEqual: filterEqual
            ? Object.keys(filterEqual).reduce(
                  (accum: NonNullable<IDeserializedPaginatedQuery["filterEqual"]>, field) => {
                      const value = filterEqual[field];
                      accum[field] = value;
                      return accum;
                  },
                  {},
              )
            : undefined,
    };
}

export function serializePaginatedQueryValues(
    deserializedPaginatedQuery: Partial<IDeserializedPaginatedQuery> | undefined,
): ISerializedPaginatedQuery {
    const {
        pageSize = DEFAULT_PAGE_SIZE,
        pageNumber = DEFAULT_PAGE_NUMBER,
        orderBy,
        filterBefore,
        filterAfter,
        filterLike,
        filterEqual,
    } = deserializedPaginatedQuery || {};

    return {
        pageSize: `${pageSize}`,
        pageNumber: `${pageNumber}`,
        filterLike: filterLike
            ? Object.keys(filterLike).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["filterLike"]>, field) => {
                      const value = filterLike[field];
                      const finalValue = value && String(value).trim();
                      if (finalValue) {
                          accum[field] = finalValue;
                      }
                      return accum;
                  },
                  {},
              )
            : undefined,
        orderBy: orderBy
            ? Object.keys(orderBy).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["orderBy"]>, field) => {
                      const value = orderBy[field];
                      accum[field] = value || "DESC";
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterBefore: filterBefore
            ? Object.keys(filterBefore).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["filterBefore"]>, field) => {
                      accum[field] = `${filterBefore.value || 0}`;
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterAfter: filterAfter
            ? Object.keys(filterAfter).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["filterAfter"]>, field) => {
                      const value = filterAfter[field];
                      accum[field] = `${value || 0}`;
                      return accum;
                  },
                  {},
              )
            : undefined,
        filterEqual: filterEqual
            ? Object.keys(filterEqual).reduce(
                  (accum: NonNullable<ISerializedPaginatedQuery["filterEqual"]>, field) => {
                      const value = filterEqual[field];
                      accum[field] = value;
                      return accum;
                  },
                  {},
              )
            : undefined,
    };
}
