import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPassWelcomeState {
    passStep: number | undefined;
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
    welcomeHide: boolean;
}

const initialState: IPassWelcomeState = {
    passStep: undefined,
    isLoading: false,
    welcomeHide: false,
    initialLoadHasBeenTriggered: false,
};

export const passStepReducer: NovelVendorSubReducer<IPassWelcomeState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_PASS_STEP": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_PASS_STEP": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "PASS_STEP": {
            return {
                ...state,
                passStep: action.payload,
                isLoading: false,
            };
        }

        case "HIDE_PASS_SETUP": {
            return {
                ...state,
                welcomeHide: true,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};
