import { IOrgCustomerRelationship } from "@novel/shared/interfaces/OrgCustomerRelationship";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IEligibleMembersState {
    data: IOrgCustomerRelationship[];
    count: number;
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: IEligibleMembersState = {
    data: [],
    count: 0,
    isLoading: false,
    initialLoadHasBeenTriggered: false,
};

export const eligibleMembersReducer: NovelVendorSubReducer<IEligibleMembersState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_ELIGIBLE_MEMBERS": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_ELIGIBLE_MEMBERS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_ELIGIBLE_MEMBERS": {
            return {
                ...state,
                data: action.payload.data,
                count: action.payload.count,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};
