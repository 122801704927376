import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadPassStep =
    (): NovelThunkVendorAction<
        "LOADING_PASS_STEP" | "PASS_STEP" | "ERROR_PASS_STEP" | "HIDE_PASS_SETUP"
    > =>
    async (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/pass-setup-step"),
            () =>
                dispatch({
                    type: "LOADING_PASS_STEP",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_PASS_STEP",
                    payload: undefined,
                }),
            ({ designCompleted, postPurchaseCompleted, rewardBalanceCompleted }) => {
                let step = 0;
                if (postPurchaseCompleted) {
                    step = 5;
                } else if (rewardBalanceCompleted) {
                    step = 3;
                } else if (designCompleted) {
                    step = 2;
                }

                if (step >= 5) {
                    return dispatch({
                        type: "HIDE_PASS_SETUP",
                        payload: undefined,
                    });
                }
                return dispatch({
                    type: "PASS_STEP",
                    payload: step,
                });
            },
        );
