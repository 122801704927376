import { Redirect } from "@shopify/app-bridge/actions";
import { isInShopifyAdminIframe } from "../../../shared/utils/isInIframe";
import { redirectOnFrontend, openInNewTab } from "../../../shared/utils/locationUtils";
import { getAppBridgeInstance } from "./appBridgeInstance";
import { shopifyGidToNumber } from "@novel/shared/utils/ensureShopifyGid";

export async function vendorPortalRedirect(
    fullRedirectUrl: string,
    openInNewTabIfNotIframe = false,
): Promise<void> {
    if (isInShopifyAdminIframe) {
        Redirect.create(await getAppBridgeInstance()).dispatch(
            Redirect.Action.REMOTE,
            fullRedirectUrl,
        );
    } else if (openInNewTabIfNotIframe) {
        openInNewTab(fullRedirectUrl);
    } else {
        redirectOnFrontend(fullRedirectUrl);
    }
}

export async function linkToShopifyOrder(
    org: { orgDomain: string },
    order: { externalId: string } | string,
): Promise<void> {
    return vendorPortalRedirect(
        `https://${org.orgDomain}/admin/orders/${shopifyGidToNumber(
            typeof order === "string" || typeof order === "number" ? order : order.externalId,
        )}`,
        true,
    );
}
