import { ICleanedDiscount } from "@novel/shared/interfaces/CleanedDiscounts";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
export interface IDiscountReducerState {
  isLoading: boolean;
  vendorDiscounts: ICleanedDiscount[];
}
const initialState: IDiscountReducerState = {
  isLoading: true,
  vendorDiscounts: []
};
export const discountsReducer: NovelVendorSubReducer<IDiscountReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_DISCOUNTS":
      {
        return {
          ...state,
          isLoading: true
        };
      }
    case "ERROR_LOADING_DISCOUNTS":
      {
        return {
          ...state,
          isLoading: false
        };
      }
    case "LOADED_DISCOUNTS":
      {
        return {
          ...state,
          isLoading: false,
          vendorDiscounts: action.payload.vendorDiscounts
        };
      }
    default:
      {
        return state;
      }
  }
};