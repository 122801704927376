import {
    fromShopifyIframePropertyName,
    myShopifyDomainUrlPropertyName,
} from "@novel/shared/utils/appConstants";
import { openInNewTab } from "@novel/shared/utils/locationUtils";
import { ensurePublicApiKey } from "@vendor-portal/utils/appBridgeInstance";
import { vendorPortalRedirect } from "@vendor-portal/utils/vendorPortalRedirect";
import { stringify } from "qs";

import { IVendorUser } from "@novel/shared/interfaces/VendorUser";
import {
    getNovelShopifyAuthenticationRedirectUrl,
    getNovelShopifyIframeAppStartingUrl,
} from "@novel/shared/utils/getShopifyAppUrl";
import { isInIFrame } from "@novel/shared/utils/isInIframe";
import {
    getFromLocalStorage,
    setInLocalStorage,
    unsetInLocalStorage,
} from "@novel/shared/utils/localStorageUtils";
import { Primitive } from "@novel/shared/utils/Primitive";
import { getParsedQuery, getParsedQueryPrimitives } from "@novel/shared/utils/getParsedQuery";
import { applyUrlQueryParamsUpdateToWindow } from "@novel/shared/utils/updateUrlQueryParams";
import { appPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";

export function getOrgDomainFromGlobals(): string | undefined {
    if (typeof window === "undefined") {
        return;
    }

    const localStorageValue = getFromLocalStorage(myShopifyDomainUrlPropertyName);
    const fromQueryString = getParsedQuery()[myShopifyDomainUrlPropertyName];
    const orgDomainFromQueryOrLocalStorage =
        // this order is important, query can override all
        (typeof fromQueryString === "string" ? fromQueryString : undefined) ||
        localStorageValue ||
        undefined;

    if (!localStorageValue && orgDomainFromQueryOrLocalStorage) {
        setInLocalStorage(myShopifyDomainUrlPropertyName, orgDomainFromQueryOrLocalStorage);
    }

    return orgDomainFromQueryOrLocalStorage;
}

export function setOrgDomainGlobals(shopUrl: string | null): void {
    applyUrlQueryParamsUpdateToWindow({ [myShopifyDomainUrlPropertyName]: shopUrl });
    if (!shopUrl) {
        unsetInLocalStorage(myShopifyDomainUrlPropertyName);
    } else {
        setInLocalStorage(myShopifyDomainUrlPropertyName, shopUrl);
    }
}

// used to log into a different store than the one presently logged into
export function redirectToShopifyAuth(
    shopUrl: string,
    openInNewTabIfNotIframe = false,
): Promise<void> {
    return vendorPortalRedirect(
        getNovelShopifyAuthenticationRedirectUrl(shopUrl, isInIFrame),
        openInNewTabIfNotIframe,
    );
}

export function redirectToNftForm(): Promise<void> {
    const shopUrl = getOrgDomainFromGlobals();
    return vendorPortalRedirect(
        `https://${shopUrl}/admin/nft-application?callback=${
            // redirect back to the current URL
            encodeURIComponent(
                `${window.location.protocol}//${window.location.hostname}${
                    window.location.pathname
                }?${myShopifyDomainUrlPropertyName}=${getOrgDomainFromGlobals()}`,
            )
        }`,
    );
}

export function openVendorPortalInStandaloneTab(): void {
    if (typeof window === "undefined") {
        return;
    }

    openInNewTab(
        `${appPrefixedHostWithProtocol}${window.location.pathname}${stringify(
            {
                ...(getParsedQuery().impersonate
                    ? { impersonate: getParsedQuery().impersonate }
                    : {}),
                [myShopifyDomainUrlPropertyName]: getOrgDomainFromGlobals(),
            },
            { addQueryPrefix: true },
        )}`,
    );
}

export function handleRequiredShopifyAuthRedirects(
    userIsLoading: boolean,
    user: IVendorUser | null,
): boolean {
    const shopUrl = getOrgDomainFromGlobals();
    const shopifyRedirectToAppNeeded = shopifyRedirectToAppNeededCheck();

    if (!userIsLoading && !user) {
        doAuthenticationRedirect(shopUrl);
        return true;
    }

    if (user && shopifyRedirectToAppNeeded && shopUrl) {
        doShopifyAppRedirect(getParsedQueryPrimitives(), shopUrl);
        return true;
    }

    return shopifyRedirectToAppNeeded;
}

function doAuthenticationRedirect(shopUrl?: string): void {
    if (typeof window === "undefined") {
        return;
    }

    if (shopUrl) {
        redirectToShopifyAuth(shopUrl);
    }
}

// redirects to shopify app (the app view inside iframe, not auth)
async function doShopifyAppRedirect(
    queryObj: { [key: string]: Primitive } = getParsedQueryPrimitives(),
    shopUrl: string,
) {
    if (typeof window === "undefined") {
        return;
    }

    return vendorPortalRedirect(
        getNovelShopifyIframeAppStartingUrl(queryObj, shopUrl, await ensurePublicApiKey(shopUrl)),
    );
}

function shopifyRedirectToAppNeededCheck(): boolean {
    return (
        Boolean(getParsedQuery()[fromShopifyIframePropertyName]) &&
        !isInIFrame &&
        !!getOrgDomainFromGlobals()
    );
}
