// #region Global Imports
// import {
//     appPrefixedHostWithProtocol,
//     vendorPortalPrefix,
// } from "@novel/shared/utils/appConstants";
import { BaseTheme } from "styled-components";
// #endregion Global Imports

// import Logo from "../../../public/logo.png";

const common: BaseTheme = {
    colors: {
        primary: "#3193dc",
        secondary: "#000",
        transparent: "transparent",
        darkGrey: "#282C34",
        blackGrey: "#20232A",
        white: "#FFFFFF",
    },
    // logo: {
    //     topBarSource: Logo.src,
    //     contextualSaveBarSource: Logo.src,
    //     url: `${appPrefixedHostWithProtocol}${vendorPortalPrefix}`,
    //     accessibilityLabel: "novel",
    // },
};

export { common };
