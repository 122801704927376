import * as React from "react";
function PassSetupIcon5(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10305_2151)">
                <path d="M3 4C3 1.79086 4.79086 0 7 0H13C15.2091 0 17 1.79086 17 4V16C17 18.2091 15.2091 20 13 20H7C4.79086 20 3 18.2091 3 16V4Z" fill="#5ADBFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.00306 2.67407C3.00307 1.22038 4.13468 0.00268555 5.57792 0.00268555H12.2174C12.2184 0.00268555 12.2195 0.00268859 12.2205 0.00269467L14.4994 0.0026978C15.9426 0.00269791 17.0742 1.22039 17.0742 2.67409L17.0743 3.90384C17.0745 3.91007 17.0746 3.91634 17.0746 3.92263V17.331C17.0746 18.7847 15.943 20.0024 14.4998 20.0024L7.85987 20.0024C7.85873 20.0024 7.85759 20.0024 7.85645 20.0024H5.57792C4.13468 20.0024 3.00306 18.7847 3.00306 17.331V16.1021C3.00281 16.0956 3.00269 16.089 3.00269 16.0824L3.00306 2.67408C3.00306 2.67408 3.00306 2.67407 3.00306 2.67407ZM4.00306 2.6741V17.331C4.00306 18.2758 4.72934 19.0024 5.57792 19.0024H14.4998C15.3483 19.0024 16.0746 18.2758 16.0746 17.331L16.0742 2.67409C16.0742 1.72933 15.348 1.0027 14.4994 1.0027L12.2174 1.00269H5.57792C4.72934 1.00269 4.00306 1.72932 4.00306 2.67408V2.6741Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.3029 7.01931C13.4299 6.98561 13.5657 6.99093 13.6894 7.03475C13.7713 7.0629 13.8461 7.10836 13.9074 7.16872C13.9179 7.17913 13.928 7.18994 13.9376 7.20111C13.958 7.22319 13.9754 7.24725 13.9896 7.2728C14.011 7.30816 14.0281 7.34603 14.0404 7.38578C14.0659 7.46815 14.0695 7.55515 14.0508 7.63931C14.0322 7.72302 13.9923 7.8002 13.9359 7.86507C13.8984 7.90927 13.8569 7.95135 13.8131 7.9901L9.60524 11.8302C9.55024 11.8971 9.48006 11.9506 9.40088 11.9877C9.31714 12.0269 9.22498 12.047 9.13205 12.047C9.03913 12.047 8.94697 12.0269 8.86323 11.9877C8.78404 11.9506 8.71385 11.8971 8.65884 11.8302L6.25241 9.66831C6.1536 9.59867 6.07866 9.50071 6.04186 9.38619C6.0038 9.26775 6.0097 9.14111 6.05815 9.0265C6.08955 8.95175 6.13809 8.88543 6.19918 8.83151C6.26029 8.77758 6.33267 8.73717 6.41085 8.71212C6.48902 8.68706 6.57188 8.67771 6.65391 8.68445C6.73579 8.69118 6.81574 8.71385 6.88825 8.75152C6.96478 8.79088 7.03535 8.83991 7.09774 8.89743L9.13198 10.7217C9.13639 10.7257 9.14065 10.7297 9.14476 10.7337L9.1537 10.725L12.9891 7.21617C13.0695 7.11992 13.1801 7.05188 13.3029 7.01931Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_10305_2151">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>;
}
export default PassSetupIcon5;