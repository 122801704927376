import { isEmpty, isNil, omit, omitBy } from "lodash";
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from "next";
import { stringify } from "qs";

import { myShopifyDomainUrlPropertyName } from "@novel/shared/utils/appConstants";
import { getFromLocalStorage } from "@novel/shared/utils/localStorageUtils";
import { getParsedQuery } from "@novel/shared/utils/getParsedQuery";
import { nextRouterPush, nextRouterReplace } from "@novel/shared/nextRouteOps";
import { isInIFrame } from "@novel/shared/utils/isInIframe";

interface VendorRoutesMap {
    // Web3 Collection Routes
    "/web3-collection": {
        serverProps: {};
        routeParams: { childRef?: any };
        queryParams: {};
    };
    "/web3-collection/create-add": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/web3-collection/import-add": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            importType: string;
        };
    };
    "/web3-collection/:collectionId": {
        serverProps: {};
        routeParams: { collectionId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/edit": {
        serverProps: {};
        routeParams: { collectionId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/create": {
        serverProps: {};
        routeParams: { collectionId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-upload": {
        serverProps: {};
        routeParams: { collectionId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-upload/:generatorId": {
        serverProps: {};
        routeParams: { collectionId: string; generatorId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-creator": {
        serverProps: {};
        routeParams: { collectionId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-creator/:generatorId": {
        serverProps: {};
        routeParams: { collectionId: string; generatorId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-metadata/:tokenId": {
        serverProps: {};
        routeParams: { collectionId: string; tokenId: string };
        queryParams: {};
    };
    "/web3-collection/:collectionId/token-metadata/:tokenId/edit": {
        serverProps: {};
        routeParams: { collectionId: string; tokenId: string };
        queryParams: {};
    };

    // Wallet Pass Routes
    "/wallet-pass": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/pass-distribution": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/pass-distribution/post-purchase": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/pass-distribution/source-tags": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/pass-distribution/distribution": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/retail": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/retail/offers": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/retail/redemptions": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/retail/analytics": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/customer": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            shouldOpen?: boolean;
        };
    };
    "/customer/:passHolderId": {
        serverProps: {};
        routeParams: { passHolderId: string };
        queryParams: {};
    };
    "/engage": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/engage/push-group": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/engage/push-group/:pushGroupId": {
        serverProps: {};
        routeParams: { pushGroupId: string };
        queryParams: {};
    };
    "/engage/smart-push-group": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/engage/location": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/engage/link": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/engage/link/:linkId": {
        serverProps: {};
        routeParams: { linkId: string };
        queryParams: {};
    };
    "/integration": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/wallet-pass/distribution": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/analytics": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };

    // Organization Location Routes
    "/locations": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };

    // Gated Experiences Routes
    "/gated-experience": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            shouldOpen?: boolean;
        };
    };

    "/gated-experience/:unversionedGateId": {
        serverProps: {};
        routeParams: { unversionedGateId: string };
        queryParams: {};
    };

    "/order": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/order/:orderId": {
        routeParams: { orderId: string };
        serverProps: {};
        queryParams: {};
    };
    "/token-transaction": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };

    "/customer-experience": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/setup": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/configure": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            selectedTab?: string;
        };
    };
    "/pass-setup": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            tab?: number;
        };
    };
    "/sync-logs/:orgId": {
        serverProps: {};
        routeParams: { orgId: string };
        queryParams: {};
    };

    // admin org routes
    "/organization": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/organization/:organizationId": {
        serverProps: {};
        routeParams: { organizationId: string };
        queryParams: {};
    };

    // admin blockchain routes
    "/blockchain": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
    "/blockchain/:webhookId": {
        serverProps: {};
        routeParams: { webhookId: string };
        queryParams: {};
    };

    "/email-template": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };

    // public routes
    "/login": {
        serverProps: {};
        routeParams: {};
        queryParams: {};
    };
}

export type VendorRoute = keyof VendorRoutesMap;

export type VendorTypedRoute<SpecificRoute extends VendorRoute> = NextPage<{
    // wrapping in "props" to avoid conflict with native React props (such as "ref")
    props: VendorRoutesMap[SpecificRoute]["routeParams"] &
        VendorRoutesMap[SpecificRoute]["serverProps"] &
        VendorRoutesMap[SpecificRoute]["queryParams"];
}> & {
    isPublicRoute?: boolean;
    isSuperUserRoute?: boolean;
    redirectIfAuthed?: boolean;
    childRef?: any;
};

export type VendorTypedGetServerSideProps<SpecificRoute extends VendorRoute> = (
    context: GetServerSidePropsContext<VendorRoutesMap[SpecificRoute]["routeParams"]> & {
        params: VendorRoutesMap[SpecificRoute];
    },
) => Promise<GetServerSidePropsResult<VendorRoutesMap[SpecificRoute]["serverProps"]>>;

export function vendorRoutePush<V extends VendorRoute>(
    vendorRoute: V,
    routeParams: VendorRoutesMap[V]["routeParams"] = {},
    queryParams: VendorRoutesMap[V]["queryParams"] = {},
): Promise<boolean> {
    return nextRouterPush(createRouteString(vendorRoute, routeParams, queryParams));
}

export function vendorRouteReplace<V extends VendorRoute>(
    vendorRoute: VendorRoute,
    routeParams: VendorRoutesMap[V]["routeParams"] = {},
    queryParams: VendorRoutesMap[V]["queryParams"] = {},
): Promise<boolean> {
    return nextRouterReplace(createRouteString(vendorRoute, routeParams, queryParams));
}

function createRouteString<V extends VendorRoute>(
    vendorRoute: VendorRoute,
    routeParams: VendorRoutesMap[V]["routeParams"] = {},
    queryParams: VendorRoutesMap[V]["queryParams"] = {},
) {
    const isInStandaloneMode = typeof window === "undefined" && !isInIFrame;
    return `${mapParamsObjectToRoute(
        vendorRoute,
        routeParams,
        isInStandaloneMode
            ? omit(getShopQueryObj(), ["host"])
            : Object.assign(getShopQueryObj(), queryParams),
    )}`;
}

function getShopQueryObj() {
    const parsedQuery = getParsedQuery();
    const shop =
        parsedQuery[myShopifyDomainUrlPropertyName] ||
        getFromLocalStorage(myShopifyDomainUrlPropertyName);
    const host = parsedQuery?.host;
    const impersonate = parsedQuery?.impersonate;
    return omitBy({ shop, host, impersonate }, isNil);
}

export function createNavQueryString(): string {
    const queryObj = getShopQueryObj();
    return isEmpty(queryObj) ? "" : stringify(queryObj, { addQueryPrefix: true });
}

function mapParamsObjectToRoute<V extends VendorRoute>(
    vendorRoute: V,
    routeParams: VendorRoutesMap[V]["routeParams"] = {},
    queryParams: VendorRoutesMap[V]["queryParams"] = {},
) {
    const route = Object.keys(routeParams).reduce((accum: string, paramName: string) => {
        const castedParamName = paramName as keyof VendorRoutesMap[V]["routeParams"];
        const paramValue = routeParams[castedParamName] as unknown as string; // unsure why this is necessary
        return accum.replace(`:${paramName}`, encodeURIComponent(paramValue));
    }, vendorRoute);
    return `${route}${stringify(queryParams, { addQueryPrefix: true })}`;
}
