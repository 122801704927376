import { ICustomerTagsRes } from "@novel/shared/interfaces/CustomerTags";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
export interface ICustomerTagsReducerState extends ICustomerTagsRes {
  isLoading: boolean;
  initialLoadHasBeenTriggered: boolean;
}
const initialState: ICustomerTagsReducerState = {
  isLoading: true,
  initialLoadHasBeenTriggered: false,
  data: []
};
export const customerTagsReducer: NovelVendorSubReducer<ICustomerTagsReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_CUSTOMER_TAGS":
      {
        return {
          ...state,
          isLoading: true,
          initialLoadHasBeenTriggered: true
        };
      }
    case "ERROR_LOADING_CUSTOMER_TAGS":
      {
        return {
          ...state,
          isLoading: false
        };
      }
    case "LOADED_CUSTOMER_TAGS":
      {
        return {
          ...state,
          data: action.payload.data,
          isLoading: false
        };
      }
    default:
      return state;
  }
};