import { IPaginatedOrgSummaries } from "@novel/shared/interfaces/Organization";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IOrganizationsReducerState extends IPaginatedOrgSummaries {
    isLoading: boolean;
}

const initialState: IOrganizationsReducerState = {
    isLoading: true,
    totalCount: 0,
    totalPages: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
};

export const organizationsReducer: NovelVendorSubReducer<IOrganizationsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_ORGANIZATIONS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_ORGANIZATIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_ORGANIZATIONS": {
            return {
                ...state,
                isLoading: false,
                ...(action.payload.organizations ?? {}),
            };
        }

        default:
            return state;
    }
};
