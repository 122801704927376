import { INovelLocationTag } from "@novel/shared/interfaces/LocationTag";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IOrgLocationTagsReducerState {
    isLoading: boolean;
    data: INovelLocationTag[];
}

const initialState: IOrgLocationTagsReducerState = {
    isLoading: true,
    data: [],
};

export const locationTagsReducer: NovelVendorSubReducer<IOrgLocationTagsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_LOCATION_TAGS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_LOCATION_TAGS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_LOCATION_TAGS": {
            return {
                ...state,
                data: action.payload.tags,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
