const nonNumericRegex = new RegExp("[^0-9-().]", "g");
// eslint-disable-next-line regexp/no-super-linear-backtracking
const bracketedValuesRegex = /\((-*\d*[^)]?\d+)\)/g;
const bracketsWithoutValuesRegex = /\((.*)\)/;
const dashRegex = /-/g;

export function textToNumber(value: number | string | undefined): number {
    if (value === undefined) {
        return 0;
    }

    if (typeof value === "number") {
        return value;
    }

    // Build regex to strip out everything except digits, decimal point and minus sign
    const unformattedValueString = value
        .replace(nonNumericRegex, "") // strip out any cruft
        .replace(bracketedValuesRegex, "-$1") // replace bracketed values with negatives
        .replace(bracketsWithoutValuesRegex, ""); // remove any brackets that do not have numeric value
    nonNumericRegex.lastIndex = 0;
    bracketedValuesRegex.lastIndex = 0;

    /**
     * Handling -ve number and bracket, eg.
     * (-100) = 100, -(100) = 100, --100 = 100
     */
    const negative = !!((unformattedValueString.match(dashRegex) || []).length % 2);
    dashRegex.lastIndex = 0;

    const absUnformatted = parseFloat(unformattedValueString.replace(dashRegex, ""));
    dashRegex.lastIndex = 0;

    const unformatted = absUnformatted * (negative ? -1 : 1);
    const amount = !isNaN(unformatted) ? unformatted : 0;

    return amount;
}
