import {
    ICryptoAddress,
    ICryptoAddressWithWallets,
    ICryptoAddressWithTokensAndWallets,
} from "@novel/shared/interfaces/CryptoAddress";
import { ICryptoWallet } from "@novel/shared/interfaces/CryptoWallet";
import map from "lodash/map";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";

export type WalletWithAddresses<T extends ICryptoAddress> = {
    cryptoWallet: ICryptoWallet;
    addresses: T[];
};

export function invertAddressesToWallets<T extends ICryptoAddressWithTokensAndWallets>(
    addresses: T[],
): WalletWithAddresses<T>[] {
    return map(
        groupBy(
            addresses.flatMap((address) =>
                address.cryptoWallets.map((cryptoWallet) => ({
                    address,
                    cryptoWallet,
                })),
            ),
            ({ cryptoWallet }) => cryptoWallet.id,
        ),
        (groupedByWallet) => {
            const addressesResult = groupedByWallet.map(({ address }) => ({ ...address }));
            return {
                cryptoWallet: {
                    ...groupedByWallet[0].cryptoWallet,
                    cryptoAddresses: addressesResult,
                },
                addresses: addressesResult,
            };
        },
    );
}

export function uninvertAddressesToWallets<T extends ICryptoAddress>(
    walletsToAddresses: WalletWithAddresses<T>[],
): (ICryptoAddressWithWallets & T)[] {
    return map(
        groupBy(
            walletsToAddresses.flatMap((walletToAddresses) =>
                walletToAddresses.addresses.map((address) => ({
                    cryptoWallet: walletToAddresses.cryptoWallet,
                    address,
                })),
            ),
            ({ address }) => address.address,
        ),
        (groupedByAddress) => ({
            ...groupedByAddress[0].address,
            cryptoWallets: groupedByAddress.map(({ cryptoWallet }) => {
                // removing "cryptoAddresses" property to avoid circular reference
                return omit(cryptoWallet, ["cryptoAddresses"]) as ICryptoWallet;
            }),
        }),
    );
}
