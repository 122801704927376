import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IRewardsTabReducerState {
    isLoading: boolean;
    data: number;
}

const initialState: IRewardsTabReducerState = {
    isLoading: true,
    data: 0,
};

export const rewardsTabReducer: NovelVendorSubReducer<IRewardsTabReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TAB": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_TAB": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TAB": {
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
