import { ICryptoAddress, ICryptoAddressWithWallets } from "@novel/shared/interfaces/CryptoAddress";
import { useMemo } from "react";
import {
    invertAddressesToWallets,
    uninvertAddressesToWallets,
    WalletWithAddresses,
} from "@novel/shared/utils/invertAddressToWallet";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";

export interface ICryptoAddressesReducerState {
    isLoading: boolean;
    cryptoAddresses: ICryptoAddressWithWallets[];
}

const initialState: ICryptoAddressesReducerState = {
    isLoading: true,
    cryptoAddresses: [],
};

export const walletsReducer: NovelVendorSubReducer<ICryptoAddressesReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADED_USER": {
            return {
                ...state,
                isLoading: false,
                ...(action.payload.user
                    ? {
                          cryptoAddresses: action.payload.user.cryptoAddresses || [],
                      }
                    : {}),
            };
        }

        case "LOG_OUT": {
            return {
                ...state,
                isLoading: false,
                cryptoAddresses: [],
            };
        }

        case "LOADING_USER":
        case "CONNECTING_WALLET":
        case "DISCONNECTING_WALLET": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_USER":
        case "ERROR_CONNECTING_WALLET":
        case "ERROR_DISCONNECTING_WALLET": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "CONNECTED_WALLET": {
            const { wallet } = action.payload;

            // TODO: cheating with "any" for now to solve for gating - can revisit types when we finalize vendor wallet auth
            const presentStateWalletFirst = invertAddressesToWallets(state.cryptoAddresses as any);

            const withoutConnectedWallet: WalletWithAddresses<ICryptoAddress>[] =
                presentStateWalletFirst.filter(({ cryptoWallet }) => cryptoWallet.id !== wallet.id);

            // Creating or overwriting the cryptoAddresses state based on the cryptoWallet ID
            const invertedStateWithNewWallet = uninvertAddressesToWallets(
                withoutConnectedWallet.concat({
                    cryptoWallet: wallet,
                    addresses: wallet.cryptoAddresses,
                }),
            );

            return {
                ...state,
                isLoading: false,
                cryptoAddresses: invertedStateWithNewWallet,
            };
        }

        case "DISCONNECTED_WALLET": {
            // Find the walletID in any of the addresses we have associated to the user and remove it from state.
            const addressWithWalletDisconnected = state.cryptoAddresses
                .map((address) => {
                    const updatedWallet = address.cryptoWallets.filter(
                        (wallet) => wallet.id !== action.payload.walletId,
                    );
                    return { ...address, cryptoWallets: updatedWallet };
                })
                .filter((address) => address.cryptoWallets.length);

            return {
                ...state,
                isLoading: false,
                cryptoAddresses: addressWithWalletDisconnected || [],
            };
        }

        default: {
            return state;
        }
    }
};

export function useWallets(options?: {
    evmOnly: boolean;
}): WalletWithAddresses<ICryptoAddressWithWallets>[] {
    const cryptoAddresses = useNovelVendorSelector((state) => state.addresses.cryptoAddresses);
    return useMemo(() => {
        const filteredAddresses = !options?.evmOnly
            ? cryptoAddresses
            : cryptoAddresses.filter((cryptoAddress) => cryptoAddress.blockchainType === "evm");

        // TODO: cheating with "any" for now to solve for gating - can revisit types when we finalize vendor wallet auth
        return invertAddressesToWallets(filteredAddresses as any);
    }, [cryptoAddresses, options?.evmOnly]);
}
