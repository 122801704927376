import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPaymasterReducerState {
    isLoading: boolean;
    testnetBalance?: string;
    mainnetBalance?: string;
    testnetPaymasterBalance?: string;
    mainnetPaymasterBalance?: string;
}

const initialState: IPaymasterReducerState = {
    isLoading: false,
};

export const paymasterReducer: NovelVendorSubReducer<IPaymasterReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_ADMIN_BALANCE_INFO": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_ADMIN_BALANCE_INFO": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_ADMIN_BALANCE_INFO": {
            return {
                ...state,
                isLoading: false,
                ...(action.payload.adminBalanceInfo ?? {}),
            };
        }

        default:
            return state;
    }
};
