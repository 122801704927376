import { Frame } from "@shopify/polaris";
import React, { ReactNode, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { isInShopifyAdminIframe } from "@novel/shared/utils/isInIframe";
import { loadNovelIntercom } from "@novel/shared/utils/loadNovelIntercom";
import { VendorPortalLayout } from "@vendor-portal/components/VendorPortalLayout";
import { vendorRoutePush } from "@vendor-portal/definitions/routeDefinitions";
import { loadUser } from "@vendor-portal/redux/actionCreators/auth";
import { loadTokenCollections } from "@vendor-portal/redux/actionCreators/tokenCollections";
import { loadGatedExperiences } from "@vendor-portal/redux/actionCreators/gatedExperiences";
import { loadSiteUrls } from "@vendor-portal/redux/actionCreators/siteUrls";
import { useNovelVendorDispatch, useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import { handleRequiredShopifyAuthRedirects } from "@vendor-portal/redux/utils/authRedirectUtils";
import { FixedCenteredLoader } from "../../../shared/components/CenteredLoader";
import { loadPriceRules } from "@vendor-portal/redux/actionCreators/priceRules";
import { useIsSuperUserSelector } from "@vendor-portal/redux/reducers/auth";
import { useActiveOrganizationSelector } from "@vendor-portal/redux/reducers/vendorUserOrganizations";
import { loadCustomerTags } from "@vendor-portal/redux/actionCreators/customerTags";
import { useIsDemoShopSelector } from "@vendor-portal/redux/reducers/shopifyShopData";
import { loadIntegrations } from "@vendor-portal/redux/actionCreators/organizations";
import { loadPassStep } from "@vendor-portal/redux/actionCreators/passWelcome";
import { loadEligibleTiers } from "@vendor-portal/redux/actionCreators/eligibleTier";
import { loadEligibleMembers } from "@vendor-portal/redux/actionCreators/eligibleMembers";
interface Props {
  readonly children?: ReactNode;
  readonly isSuperUserRoute?: boolean;
  readonly isPublicRoute?: boolean;
  readonly redirectIfAuthed?: boolean;
}
export function AuthContainer({
  children,
  isSuperUserRoute,
  redirectIfAuthed,
  isPublicRoute
}: Props): JSX.Element {
  const dispatch = useNovelVendorDispatch();
  const isSuperUser = useIsSuperUserSelector();
  const user = useNovelVendorSelector(state => state.auth.user);
  const isDemoShop = useIsDemoShopSelector();
  const userIsLoading = useNovelVendorSelector(state => state.auth.isLoading);

  // check if integration is loading (won't be loaded at all for demo shops)
  // need selector to come first to avoid changing number of hooks
  const isLoadingIntegrations = useNovelVendorSelector(state => !!state.integrations.isLoadingIntegrations) && !isDemoShop;
  const hasAuthToken = useNovelVendorSelector(state => !!state.auth.authToken);
  const tokenCollectionsInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.tokenCollections.initialLoadHasBeenTriggered);
  const eligibleMembersInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.eligibleMembers.initialLoadHasBeenTriggered);
  const passWelcomeStepInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.passWelcomeStep.initialLoadHasBeenTriggered);
  const eligibleTiersInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.tiers.initialLoadHasBeenTriggered);
  const siteUrlsInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.siteUrls.initialLoadHasBeenTriggered);
  const priceRulesInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.priceRules.initialLoadHasBeenTriggered);
  const customerTagsInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.customerTags.initialLoadHasBeenTriggered);
  const gatedExperiencesInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.gatedExperiences.initialLoadHasBeenTriggered);
  const orgIntegrationsInitialLoadHasBeenTriggered = useNovelVendorSelector(state => !!state.integrations.initialLoadHasBeenTriggered);
  const isPotentiallyRedirecting = (userIsLoading || user) && hasAuthToken && redirectIfAuthed;
  const isRedirecting = !isPublicRoute && handleRequiredShopifyAuthRedirects(userIsLoading, user); // redirect only once user auth state is loaded
  const org = useActiveOrganizationSelector();
  useEffect(() => {
    // userIsLoading initializes as true
    if (!user && userIsLoading) {
      dispatch(loadUser());
    }
    const userNotAuthorized = isSuperUserRoute && user && !user.isSuperUser;
    if (userNotAuthorized || user && redirectIfAuthed) {
      vendorRoutePush("/wallet-pass");
    }
  }, [dispatch, user, userIsLoading, isDemoShop, isSuperUserRoute, redirectIfAuthed]);
  useEffect(() => {
    if (user) {
      if (!isDemoShop) {
        // defaults to "isLoading: true"
        // used for initial page redirect, we don't want to load twice
        if (!tokenCollectionsInitialLoadHasBeenTriggered && org?.canSeeWeb3) {
          dispatch(loadTokenCollections());
        }
        if (!gatedExperiencesInitialLoadHasBeenTriggered) {
          dispatch(loadGatedExperiences());
        }
        if (!customerTagsInitialLoadHasBeenTriggered) {
          dispatch(loadCustomerTags());
        }
        if (!passWelcomeStepInitialLoadHasBeenTriggered) {
          dispatch(loadPassStep());
        }
        if (!siteUrlsInitialLoadHasBeenTriggered) {
          dispatch(loadSiteUrls());
        }
        if (!priceRulesInitialLoadHasBeenTriggered) {
          dispatch(loadPriceRules({
            syncDbWithShopify: false
          }));
        }
        if (!orgIntegrationsInitialLoadHasBeenTriggered) {
          dispatch(loadIntegrations());
        }
      }
      if (!eligibleTiersInitialLoadHasBeenTriggered) {
        dispatch(loadEligibleTiers());
      }
      if (!eligibleMembersInitialLoadHasBeenTriggered) {
        dispatch(loadEligibleMembers());
      }
    }
  }, [dispatch, user, passWelcomeStepInitialLoadHasBeenTriggered, eligibleTiersInitialLoadHasBeenTriggered, isDemoShop, tokenCollectionsInitialLoadHasBeenTriggered, customerTagsInitialLoadHasBeenTriggered, priceRulesInitialLoadHasBeenTriggered, siteUrlsInitialLoadHasBeenTriggered, gatedExperiencesInitialLoadHasBeenTriggered, orgIntegrationsInitialLoadHasBeenTriggered, eligibleMembersInitialLoadHasBeenTriggered, org?.canSeeWeb3]);
  useEffect(() => {
    if (user && org) {
      loadNovelIntercom({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        isInShopifyAdminIframe,
        orgName: org?.orgName,
        domain: org?.shopifyShopData?.domain || org?.orgDomain,
        myShopifyDomain: org?.shopifyShopData?.myShopifyDomain,
        orgCustomerEmail: org?.shopifyShopData?.customer_email,
        orgPartnerEmail: org?.shopifyShopData?.email,
        shopOwner: org?.shopifyShopData?.shop_owner
      });
    }
  }, [user, org]);
  useEffect(() => {
    if (user) {
      // If user is valid we should track him using this id and email, to track specific issues
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: [user.firstName, user.lastName].map(v => v?.trim()).filter(Boolean).join(" ")
      });
    } else {
      // If user is not valid, we should remove any user information
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }, [isSuperUser, user]);
  if (isPublicRoute && !isPotentiallyRedirecting) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (isRedirecting || userIsLoading || isPotentiallyRedirecting || isLoadingIntegrations) {
    return <Frame>
                <FixedCenteredLoader />
            </Frame>;
  }
  return <React.Fragment>
            {isSuperUserRoute ? <Frame>{children}</Frame> : <VendorPortalLayout>{children}</VendorPortalLayout>}
        </React.Fragment>;
}