import { IOrganizationShallows } from "@novel/shared/interfaces/Organization";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IOrganizationsDropdownReducerState extends IOrganizationShallows {
    isLoading: boolean;
}

const initialState: IOrganizationsDropdownReducerState = {
    isLoading: true,
    data: [],
};

export const organizationsDropdownReducer: NovelVendorSubReducer<
    IOrganizationsDropdownReducerState
> = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_ORGANIZATIONS_DROPDOWN": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_ORGANIZATIONS_DROPDOWN": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_ORGANIZATIONS_DROPDOWN": {
            return {
                ...state,
                isLoading: false,
                ...(action.payload.organizations ?? []),
            };
        }

        default:
            return state;
    }
};
