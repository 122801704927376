import { vendorJwtPropertyName, VENDOR_HEADERS } from "@novel/shared/utils/appConstants";
import { getFromLocalStorage } from "../../../shared/utils/localStorageUtils";

export function getVendorAuthToken() {
    return getFromLocalStorage(vendorJwtPropertyName);
}

export function makeBearerAuthTokenHeaderObj(
    authToken: string | undefined,
): {} | { [VENDOR_HEADERS.AUTHORIZATION]: string } {
    return !authToken
        ? {}
        : {
              [VENDOR_HEADERS.AUTHORIZATION]: `Bearer ${authToken}`,
          };
}
