import { novelSessionTokenDataStorageKey } from "@novel/shared/utils/appStorefrontConstants";

let cacheForHasLocalStorageAccessIsSet = false;
let cachedResultForHasLocalStorageAccess: boolean;

const fallbackLocalStorage: { [key: string]: string } = {};

export function hasLocalStorageAccess(): boolean {
    if (cacheForHasLocalStorageAccessIsSet) {
        return cachedResultForHasLocalStorageAccess;
    }

    if (typeof window === "undefined") {
        cachedResultForHasLocalStorageAccess = false;
    } else {
        try {
            // "_" is arbitrary, checking that accessing localStorage doesn't throw an error
            window.localStorage.getItem("_");
            cachedResultForHasLocalStorageAccess = !!window.localStorage;
        } catch (e) {
            cachedResultForHasLocalStorageAccess = false;
        }
    }

    cacheForHasLocalStorageAccessIsSet = true;
    return cachedResultForHasLocalStorageAccess;
}

export function getFromLocalStorage(localStorageProperty: string): string | undefined {
    let value: string | undefined;
    if (hasLocalStorageAccess()) {
        const fromLocalStorage = window.localStorage.getItem(localStorageProperty);
        value = isNil(fromLocalStorage) ? undefined : fromLocalStorage;

        if (!value) {
            if (localStorageProperty === novelSessionTokenDataStorageKey) {
                // check legacy value and use if valid
                const sessionTokenJson = getFromLocalStorage("__novel_s__");
                value = sessionTokenJson?.includes("sessionToken") ? sessionTokenJson : undefined;
                if (value) {
                    setInLocalStorage(novelSessionTokenDataStorageKey, value);
                    unsetInLocalStorage("__novel_s__");
                }
            }
        }
    } else {
        value = fallbackLocalStorage[localStorageProperty];
    }

    return !isNil(value) && value !== "null" && value !== "undefined" ? value : undefined;
}

export function setInLocalStorage(localStorageProperty: string, localStorageValue: string): void {
    if (
        !isNil(localStorageValue) &&
        localStorageValue !== "null" &&
        localStorageValue !== "undefined"
    ) {
        if (hasLocalStorageAccess()) {
            window.localStorage.setItem(localStorageProperty, localStorageValue);
        } else {
            fallbackLocalStorage[localStorageProperty] = localStorageValue;
        }
    }
}

export function unsetInLocalStorage(localStorageProperty: string): void {
    if (hasLocalStorageAccess()) {
        window.localStorage.removeItem(localStorageProperty);
    } else {
        delete fallbackLocalStorage[localStorageProperty];
    }

    // a separate in-memory cache mechanism we use that we want to clear
    if (typeof window !== "undefined" && (window as any).__novel_cache__) {
        delete (window as any).__novel_cache__[localStorageProperty];
    }
}

// put here directly to optimize for bundle size
function isNil(value: any): value is null | undefined {
    return value === null || value === undefined;
}
