import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";
export const loadCustomerTags = (): NovelThunkVendorAction<"LOADING_CUSTOMER_TAGS" | "ERROR_LOADING_CUSTOMER_TAGS" | "LOADED_CUSTOMER_TAGS"> => dispatch => asyncDispatch(typedFrontendVendorApi.getReq("/api/vendor/customer-tags"), () => dispatch({
  type: "LOADING_CUSTOMER_TAGS",
  payload: undefined
}), failure => dispatch({
  type: "ERROR_LOADING_CUSTOMER_TAGS",
  payload: {
    errorMessage: failure.error.message
  }
}), customerTags => dispatch({
  type: "LOADED_CUSTOMER_TAGS",
  payload: customerTags
}));