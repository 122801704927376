import { myShopifyDomainUrlPropertyName } from "@novel/shared/utils/appConstants";
import { CryptoOrFiatCurrencyCode } from "@novel/shared/utils/formatMoney";
import {
    computeIsDevelopmentShop,
    computeIsNovelDevShop,
} from "@novel/shared/utils/computeFromShopStatus";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import { getOrgDomainFromGlobals } from "@vendor-portal/redux/utils/authRedirectUtils";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

import { IShopifyShopData } from "../../../../../shared/interfaces/ShopifyShopData";

export interface IShopifyStoreDataReducerState {
    isLoading: boolean;
    [myShopifyDomainUrlPropertyName]?: string;
    shopifyShopData?: IShopifyShopData;
}

const initialState: IShopifyStoreDataReducerState = {
    [myShopifyDomainUrlPropertyName]: getOrgDomainFromGlobals(),
    isLoading: true,
};

export const shopifyStoreDataReducer: NovelVendorSubReducer<IShopifyStoreDataReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADED_USER": {
            const vendorUserOrgRole = action.payload.user?.vendorOrgRoles?.find(
                (orgRole) => orgRole.org.id === action.payload.activeOrganizationId,
            );

            if (!vendorUserOrgRole || !vendorUserOrgRole.org.shopifyShopData) {
                return {
                    ...state,
                    [myShopifyDomainUrlPropertyName]: undefined,
                    isLoading: false,
                };
            }

            return {
                ...state,
                [myShopifyDomainUrlPropertyName]: vendorUserOrgRole.org.orgDomain,
                shopifyShopData: vendorUserOrgRole.org.shopifyShopData,
            };
        }

        default: {
            return state;
        }
    }
};

export function useShopCurrencySelector(): CryptoOrFiatCurrencyCode | undefined {
    return useNovelVendorSelector((state) => state.shopifyStoreData.shopifyShopData?.currency);
}

export function useIsDevelopmentShopSelector(): boolean {
    return useNovelVendorSelector(
        (state) =>
            !!state.shopifyStoreData.shopifyShopData &&
            computeIsDevelopmentShop(state.shopifyStoreData.shopifyShopData),
    );
}

export function useIsNovelDevShopSelector(): boolean {
    return useNovelVendorSelector(
        (state) =>
            !!state.shopifyStoreData.shopifyShopData &&
            computeIsNovelDevShop(state.shopifyStoreData.shopifyShopData),
    );
}

export function useIsDemoShopSelector(): boolean {
    return useNovelVendorSelector((state) => !state.shopifyStoreData?.shopifyShopData);
}
