import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadTokenCollections =
    (): NovelThunkVendorAction<
        "LOADING_TOKEN_COLLECTIONS" | "ERROR_LOADING_TOKEN_COLLECTIONS" | "LOADED_TOKEN_COLLECTIONS"
    > =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/web3-collection"),
            () =>
                dispatch({
                    type: "LOADING_TOKEN_COLLECTIONS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_TOKEN_COLLECTIONS",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (orgTokenCollectionsRes) =>
                dispatch({
                    type: "LOADED_TOKEN_COLLECTIONS",
                    payload: {
                        orgTokenCollectionsRes,
                    },
                }),
        );
