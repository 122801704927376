import { TypedFrontendVendorApi } from "@novel/shared/typedVendorApi/TypedVendorRoutes";
import { isInIFrame } from "@novel/shared/utils/isInIframe";
import { getParsedQuery } from "@novel/shared/utils/getParsedQuery";
import { getOrgDomainFromGlobals } from "@vendor-portal/redux/utils/authRedirectUtils";
import { getAppBridgeSessionToken } from "@vendor-portal/utils/appBridgeInstance";

import { getVendorAuthToken } from "./getVendorAuthToken";

export const typedFrontendVendorApi = new TypedFrontendVendorApi(
    () => !!getParsedQuery().impersonate,
    getVendorAuthToken,
    getOrgDomainFromGlobals,
    isInIFrame && !getVendorAuthToken() ? () => getAppBridgeSessionToken() : undefined,
);
