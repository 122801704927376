import { IPaginatedGatedLineItems } from "@novel/shared/interfaces/Gate";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IGatedLineItemsReducerState extends IPaginatedGatedLineItems {
    isLoading: boolean;
}

const initialState: IGatedLineItemsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const gatedLineItemsReducer: NovelVendorSubReducer<IGatedLineItemsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_GATED_LINE_ITEMS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_GATED_LINE_ITEMS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_GATED_LINE_ITEMS": {
            return {
                ...state,
                totalCount: action.payload.paginatedGatedLineItems.totalCount,
                pageSize: action.payload.paginatedGatedLineItems.pageSize,
                pageNumber: action.payload.paginatedGatedLineItems.pageNumber,
                totalPages: action.payload.paginatedGatedLineItems.totalPages,
                data: action.payload.paginatedGatedLineItems.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
