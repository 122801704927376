import { parse } from "qs";
import { Primitive } from "./Primitive";

interface ParsedQsWithBooleans {
    [key: string]:
        | undefined
        | string
        | string[]
        | ParsedQsWithBooleans
        | ParsedQsWithBooleans[]
        | boolean;
}

export function getParsedQuery(
    queryString: string = typeof window !== "undefined" ? window.location.search : "",
): ParsedQsWithBooleans {
    return typeof window !== "undefined"
        ? recursivelyConvertBooleanStringsToBooleans(
              parse(queryString, { ignoreQueryPrefix: true }),
          )
        : {};
}

export function getParsedQueryPrimitives(queryString: string = window.location.search): {
    [key: string]: Primitive;
} {
    const parsedQuery = getParsedQuery(queryString);

    Object.keys(parsedQuery).forEach((key) => {
        const value = parsedQuery[key];
        if ((typeof value !== "object" && typeof value !== "function") || value === null) {
            delete parsedQuery[key];
        }
    });

    // hacky but nbd here
    return parsedQuery as any;
}

// sloppy but want booleans instead of strings
function recursivelyConvertBooleanStringsToBooleans(
    parsedQueryString: object,
): ParsedQsWithBooleans {
    const result: ParsedQsWithBooleans = parsedQueryString as ParsedQsWithBooleans;
    Object.keys(result).forEach((key) => {
        const value = result[key];
        const valueType = typeof value;
        if (valueType === "string") {
            const cleanedValue = (value as string).trim().toLowerCase();
            if (cleanedValue === "true") {
                result[key] = true;
            } else if (cleanedValue === "false") {
                result[key] = false;
            }
        } else if (valueType === "undefined") {
            result[key] = true;
        } else if (valueType === "object") {
            recursivelyConvertBooleanStringsToBooleans(value as ParsedQsWithBooleans);
        }
    });

    return result;
}
