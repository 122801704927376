import { IGatedTokenAttributes } from "@novel/shared/interfaces/TokenMetadata";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITraitAttributesReducerState {
    isLoading: boolean;
    tokenAttributes: IGatedTokenAttributes[];
}

const initialState: ITraitAttributesReducerState = {
    isLoading: true,
    tokenAttributes: [],
};

export const tokenAttributeReducer: NovelVendorSubReducer<ITraitAttributesReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TRAITS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_TRAITS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TRAITS": {
            return {
                ...state,
                isLoading: false,
                tokenAttributes: action.payload.tokenAttributes,
            };
        }

        default: {
            return state;
        }
    }
};
