import { IPaginatedTokenMetadataTableItem } from "@novel/shared/interfaces/TokenMetadata";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITokenMetadatasReducerState extends IPaginatedTokenMetadataTableItem {
    isLoading: boolean;
}

const initialState: ITokenMetadatasReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const tokenMetadatasReducer: NovelVendorSubReducer<ITokenMetadatasReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TOKEN_METADATAS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_TOKEN_METADATAS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TOKEN_METADATAS": {
            return {
                ...state,
                totalCount: action.payload.paginatedTokenMetadatas.totalCount,
                pageSize: action.payload.paginatedTokenMetadatas.pageSize,
                pageNumber: action.payload.paginatedTokenMetadatas.pageNumber,
                totalPages: action.payload.paginatedTokenMetadatas.totalPages,
                data: action.payload.paginatedTokenMetadatas.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
