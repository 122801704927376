import { IPaginatedBlockchainTransactionWithAddressesAndCustomers } from "@novel/shared/interfaces/BlockchainTransaction";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITransactionsReducerState
    extends IPaginatedBlockchainTransactionWithAddressesAndCustomers {
    isLoading: boolean;
}

const initialState: ITransactionsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const transactionsReducer: NovelVendorSubReducer<ITransactionsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TRANSACTIONS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_TRANSACTIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TRANSACTIONS": {
            return {
                ...state,
                totalCount: action.payload.paginatedTransactions.totalCount,
                pageSize: action.payload.paginatedTransactions.pageSize,
                pageNumber: action.payload.paginatedTransactions.pageNumber,
                totalPages: action.payload.paginatedTransactions.totalPages,
                tokenId: "",
                data: action.payload.paginatedTransactions.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
