import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
import { INovelApiToken } from "@novel/shared/interfaces/NovelApiToken";

export interface IApiTokensReducerState {
    isLoading: boolean;
    tokens: INovelApiToken[];
}

const initialState: IApiTokensReducerState = {
    isLoading: true,
    tokens: [],
};

export const apiTokensReducer: NovelVendorSubReducer<IApiTokensReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOG_OUT": {
            return {
                ...state,
                isLoading: false,
                tokens: [],
            };
        }

        case "LOADING_API_TOKENS":
        case "CREATING_API_TOKEN":
        case "DELETING_API_TOKEN":
        case "UPDATING_API_TOKEN": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_API_TOKENS":
        case "ERROR_CREATING_API_TOKEN":
        case "ERROR_DELETING_API_TOKEN":
        case "ERROR_UPDATING_API_TOKEN": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_API_TOKENS": {
            return {
                ...state,
                isLoading: false,
                ...(action.payload.apiTokens
                    ? {
                          tokens: action.payload.apiTokens || [],
                      }
                    : {}),
            };
        }

        case "CREATED_API_TOKEN": {
            return {
                ...state,
                isLoading: false,
                tokens: [action.payload.token, ...state.tokens],
            };
        }

        case "UPDATED_API_TOKEN": {
            return {
                ...state,
                isLoading: false,
                tokens: state.tokens.map((apiToken) => {
                    // update matching token
                    if (apiToken.id === action.payload.token.id) {
                        return action.payload.token;
                    }

                    return apiToken;
                }),
            };
        }

        case "DELETED_API_TOKEN": {
            return {
                ...state,
                isLoading: false,
                tokens: state.tokens.filter((apiToken) => apiToken.id !== action.payload.tokenId),
            };
        }

        default: {
            return state;
        }
    }
};
