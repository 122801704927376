import { IFullGateRes } from "@novel/shared/interfaces/Gate";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IGatedExperiencesReducerState extends IFullGateRes {
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: IGatedExperiencesReducerState = {
    isLoading: true,
    initialLoadHasBeenTriggered: false,
    data: [],
};

export const gatedExperiencesReducer: NovelVendorSubReducer<IGatedExperiencesReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_GATED_EXPERIENCES": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_GATED_EXPERIENCES": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_GATED_EXPERIENCES": {
            return {
                ...state,
                data: action.payload.fullGatesRes.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
