import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";
import { Id } from "react-toastify";

export const loadPriceRules =
    (queryParams: {
        syncDbWithShopify: boolean;
        onSuccessToast?: (successContent: string, toastDuration?: number | undefined) => Id;
    }): NovelThunkVendorAction<
        "LOADING_PRICE_RULES" | "ERROR_LOADING_PRICE_RULES" | "LOADED_PRICE_RULES"
    > =>
    (dispatch) => {
        return asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/org-price-rules", {
                query: queryParams,
            }),
            () =>
                dispatch({
                    type: "LOADING_PRICE_RULES",
                    payload: undefined,
                }),
            (failure) => {
                return dispatch({
                    type: "ERROR_LOADING_PRICE_RULES",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            (priceRulesRes) => {
                if (queryParams.onSuccessToast)
                    queryParams.onSuccessToast("Synced Discount Codes from Shopify");
                return dispatch({
                    type: "LOADED_PRICE_RULES",
                    payload: {
                        priceRulesRes,
                    },
                });
            },
        );
    };
