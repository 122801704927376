import { isShopifyEmbedded } from "@shopify/app-bridge/utilities";
import { getParsedQuery } from "./getParsedQuery";

export const isInIFrame =
    !!(typeof window !== "undefined" && window?.parent?.location !== window?.location) ||
    isShopifyEmbedded();

const parsedQuery = getParsedQuery();
export const isInShopifyAdminIframe: boolean =
    isInIFrame && !!parsedQuery.shop && !!parsedQuery.hmac && !!parsedQuery.timestamp;
