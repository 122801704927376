import { IShopifyShopData, IShopifyShopDataCreate } from "@novel/shared/interfaces/ShopifyShopData";

export function computeIsDevelopmentShop(shopifyShopData: IShopifyShopDataCreate): boolean {
    const devStorePlanDisplayNames = ["development"];
    const devStorePlanNames = ["partner_test", "staff", "affiliate"];
    return (
        shopifyShopData.is_partner_development_shop ||
        devStorePlanNames.includes(shopifyShopData.plan_name.trim().toLowerCase()) ||
        devStorePlanDisplayNames.includes(shopifyShopData.plan_display_name.trim().toLowerCase())
    );
}

export function computeIsTrialShop(shopifyShopData: IShopifyShopDataCreate): boolean {
    const trialPlanNameString = "trial";
    return (
        shopifyShopData.setup_required ||
        shopifyShopData.plan_name.trim().toLowerCase().includes(trialPlanNameString) ||
        shopifyShopData.plan_display_name.trim().toLowerCase().includes(trialPlanNameString)
    );
}

export function computeIsShopifyPlusShop(shopifyShopData: IShopifyShopData): boolean {
    return (
        shopifyShopData.is_shopify_plus ||
        shopifyShopData.plan_name.trim().toLowerCase().includes("plus") ||
        shopifyShopData.plan_display_name.trim().toLowerCase().includes("plus")
    );
}

export function computeIsNovelDevShop(shopifyShopData: IShopifyShopDataCreate): boolean {
    return (
        shopifyShopData.myShopifyDomain.includes("novel-dev") ||
        shopifyShopData.myShopifyDomain.includes("novel-staging") ||
        shopifyShopData.myShopifyDomain.includes("novel-prod") ||
        shopifyShopData.myShopifyDomain.includes("novel-demo")
    );
}

export function computeIsShopUnavailable(shopifyShopData: IShopifyShopDataCreate): boolean {
    return (
        computeIsShopClosed(shopifyShopData) ||
        shopifyShopData.plan_name.trim().toLowerCase().includes("frozen") ||
        shopifyShopData.plan_name.trim().toLowerCase().includes("fraudulent")
    );
}

export function computeIsShopClosed(shopifyShopData: IShopifyShopDataCreate): boolean {
    return (
        shopifyShopData.plan_name.trim().toLowerCase().includes("canceled") ||
        shopifyShopData.plan_name.trim().toLowerCase().includes("cancelled")
    );
}
