import { IPaginatedOrgLocation } from "@novel/shared/interfaces/OrgLocation";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IOrgLocationsReducerState extends IPaginatedOrgLocation {
    isLoading: boolean;
}

const initialState: IOrgLocationsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const orgLocationsReducer: NovelVendorSubReducer<IOrgLocationsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_ORG_LOCATIONS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_ORG_LOCATIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_ORG_LOCATIONS": {
            return {
                ...state,
                totalCount: action.payload.paginatedOrgLocations.totalCount,
                pageSize: action.payload.paginatedOrgLocations.pageSize,
                pageNumber: action.payload.paginatedOrgLocations.pageNumber,
                totalPages: action.payload.paginatedOrgLocations.totalPages,
                data: action.payload.paginatedOrgLocations.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
