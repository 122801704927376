import { IInCommOffer } from "@novel/shared/interfaces/RetailNetwork";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IRetailOfferReducerState {
    isLoading: boolean;
    data: IInCommOffer[];
}

const initialState: IRetailOfferReducerState = {
    isLoading: true,
    data: [],
};

export const retailOfferReducer: NovelVendorSubReducer<IRetailOfferReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_RETAIL_OFFERS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_RETAIL_OFFERS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_RETAIL_OFFERS": {
            return {
                ...state,
                data: action.payload.retailOffers,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
