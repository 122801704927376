import { IOrganization } from "@novel/shared/interfaces/Organization";
import { myShopifyDomainUrlPropertyName } from "@novel/shared/utils/appConstants";
import { orgDomainMatch } from "@novel/shared/utils/orgDomainMatch";
import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadUser =
    (): NovelThunkVendorAction<"LOADING_USER" | "ERROR_LOADING_USER" | "LOADED_USER"> =>
    async (dispatch, getState) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/auth/session"),
            () =>
                dispatch({
                    type: "LOADING_USER",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_USER",
                    payload: {
                        user: null,
                        authToken: null,
                        errorMessage: failure.error.message,
                    },
                }),
            ({ user, authToken }) => {
                // choose active organization
                const newOrganizations = (user?.vendorOrgRoles || []).map((orgRole) => orgRole.org);
                let nextActiveOrganizationId = getState().vendorOrganizations.activeOrganizationId;
                if (!nextActiveOrganizationId) {
                    const shopUrl = getState().shopifyStoreData[myShopifyDomainUrlPropertyName];
                    try {
                        const organizationMatchingShop =
                            (shopUrl &&
                                newOrganizations.find((org) => orgDomainMatch(org, shopUrl))) ||
                            null;
                        nextActiveOrganizationId = organizationMatchingShop?.id || null;
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }

                if (!nextActiveOrganizationId) {
                    nextActiveOrganizationId =
                        newOrganizations.length === 1 ? newOrganizations[0].id : null;
                }

                return dispatch({
                    type: "LOADED_USER",
                    payload: {
                        user: user || null,
                        authToken: authToken || null,
                        ...(nextActiveOrganizationId
                            ? { activeOrganizationId: nextActiveOrganizationId }
                            : {}),
                    },
                });
            },
        );

export const loadNewUserOrgInfo =
    (orgId: string, org: IOrganization): NovelThunkVendorAction<"LOADED_USER"> =>
    async (dispatch, getState) => {
        const { user } = getState().auth;
        if (user && user.vendorOrgRoles.length > 0 && org) {
            user.vendorOrgRoles = user.vendorOrgRoles.map((item) => {
                if (item.org.id === orgId) {
                    return {
                        ...item,
                        org: { ...org },
                    };
                }
                return item;
            });
        }
        const newOrganizations = (user?.vendorOrgRoles || []).map((orgRole) => orgRole.org);
        let nextActiveOrganizationId = getState().vendorOrganizations.activeOrganizationId;
        if (!nextActiveOrganizationId) {
            const shopUrl = getState().shopifyStoreData[myShopifyDomainUrlPropertyName];
            try {
                const organizationMatchingShop =
                    (shopUrl && newOrganizations.find((org) => orgDomainMatch(org, shopUrl))) ||
                    null;
                nextActiveOrganizationId = organizationMatchingShop?.id || null;
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        if (!nextActiveOrganizationId) {
            nextActiveOrganizationId =
                newOrganizations.length === 1 ? newOrganizations[0].id : null;
        }

        return dispatch({
            type: "LOADED_USER",
            payload: {
                ...getState().auth,
                user: user || null,
                ...(nextActiveOrganizationId
                    ? { activeOrganizationId: nextActiveOrganizationId }
                    : {}),
            },
        });
    };
