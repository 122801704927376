import { stringify } from "qs";
import {
    fromShopifyIframePropertyName,
    myShopifyDomainUrlPropertyName,
    vendorApiPrefix,
    vendorPortalPrefix,
} from "./appConstants";
import { Primitive } from "./Primitive";
import { getParsedQuery } from "./getParsedQuery";
import { appPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";

export function getNovelShopifyIframeAppStartingUrl(
    queryObj: { [key: string]: Primitive } = {},
    shopUrl: string,
    apiKey?: string,
): string {
    const shopifyAdminBaseUrl = `https://${shopUrl}/admin/apps/${
        apiKey || process.env.NEXT_PUBLIC_SHOPIFY_API_PUBLIC_KEY
    }`;

    return `${shopifyAdminBaseUrl}${vendorPortalPrefix}${stringify(queryObj, {
        addQueryPrefix: true,
    })}`;
}

export function getNovelShopifyAppUrlOnFrontEndOrBackEnd(
    queryObj: { [key: string]: Primitive },
    shopUrl?: string,
    apiKey?: string,
): string {
    return shopUrl
        ? getNovelShopifyIframeAppStartingUrl(queryObj, shopUrl, apiKey)
        : `${appPrefixedHostWithProtocol}${vendorPortalPrefix}${stringify(queryObj, {
              addQueryPrefix: true,
          })}`;
}

export function getNovelShopifyAuthenticationRedirectUrl(
    shopUrl: string,
    isInIFrame?: boolean,
): string {
    const queryString = stringify(
        {
            ...(Boolean(getParsedQuery()[fromShopifyIframePropertyName]) || isInIFrame
                ? { [fromShopifyIframePropertyName]: true }
                : {}),
            ...(shopUrl ? { [myShopifyDomainUrlPropertyName]: shopUrl } : {}),
        },
        { addQueryPrefix: true },
    );

    return `${appPrefixedHostWithProtocol}${vendorApiPrefix}/auth/shopify${queryString}`;
}

export function getLoginRedirectPath(): string {
    return `/login${stringify(getParsedQuery(), { addQueryPrefix: true })}`;
}
