import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadEligibleMembers =
    (): NovelThunkVendorAction<
        "LOADING_ELIGIBLE_MEMBERS" | "ERROR_LOADING_ELIGIBLE_MEMBERS" | "LOADED_ELIGIBLE_MEMBERS"
    > =>
    async (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/pass-holder/eligible-customer", {
                query: {
                    count: "true",
                },
            }),
            () =>
                dispatch({
                    type: "LOADING_ELIGIBLE_MEMBERS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_ELIGIBLE_MEMBERS",
                    payload: undefined,
                }),
            ({ data, count }) =>
                dispatch({
                    type: "LOADED_ELIGIBLE_MEMBERS",
                    payload: {
                        data: data || [],
                        count: count || 0,
                    },
                }),
        );
