import { IPaginatedSyncLog } from "@novel/shared/interfaces/SyncLog";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ISyncLogsReducerState extends IPaginatedSyncLog {
    isLoading: boolean;
}

const initialState: ISyncLogsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const syncLogsReducer: NovelVendorSubReducer<ISyncLogsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_SYNC_LOGS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_SYNC_LOGS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_SYNC_LOGS": {
            return {
                ...state,
                totalCount: action.payload.syncLogs.totalCount,
                pageSize: action.payload.syncLogs.pageSize,
                pageNumber: action.payload.syncLogs.pageNumber,
                totalPages: action.payload.syncLogs.totalPages,
                data: action.payload.syncLogs.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
