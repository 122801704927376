import React, { MouseEventHandler } from "react";
import cx from "classnames";
import styled from "styled-components";
interface IIconWrapperProps {
  readonly inline?: boolean;
  readonly className?: string;
  readonly color?: string;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
  readonly onMouseDown?: MouseEventHandler<HTMLDivElement>;
  readonly onMouseDownCapture?: MouseEventHandler<HTMLDivElement>;
  readonly icon: React.FC<React.SVGProps<SVGSVGElement>>;
  readonly stroke?: string;
  readonly fill?: string;
  readonly style?: {};
}
const StyledIconWrapper = styled.div<{
  inline?: boolean;
  color?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onMouseDownCapture?: MouseEventHandler<HTMLDivElement>;
}>`
    align-items: center;
    color: ${props => props.color || "currentColor"};
    display: ${props => props.inline ? "inline-flex" : "flex"};
    justify-content: center;
    margin: 0;

    .wrapped-icon {
        fill: currentColor;
    }
`;
export function IconWrapper({
  inline,
  className,
  icon,
  color,
  onClick,
  onMouseDown,
  onMouseDownCapture,
  stroke,
  fill,
  style
}: IIconWrapperProps): JSX.Element {
  const Icon = icon;
  return <StyledIconWrapper color={color} inline={inline} onClick={onClick} onMouseDown={onMouseDown} onMouseDownCapture={onMouseDownCapture} className={cx("icon-wrapper", "Polaris-Icon", className || "")} style={style}>
            <Icon className="wrapped-icon Polaris-Icon__Svg" height="100%" width="100%" stroke={stroke} fill={fill} />
        </StyledIconWrapper>;
}