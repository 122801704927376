import * as React from "react";
function BarGraphIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_9501_1350)">
                <path d="M17.8152 15.1118H16.6017V1.0508C16.6017 0.471212 16.1054 0 15.495 0H13.5793C12.9689 0 12.4726 0.471212 12.4726 1.0508V15.1118H11.1698V4.51185C11.1698 3.93226 10.6735 3.46105 10.0631 3.46105H8.14742C7.53698 3.46105 7.04069 3.93226 7.04069 4.51185V15.1118H5.73793V9.79178C5.73793 9.21219 5.24164 8.74098 4.6312 8.74098H2.71552C2.10509 8.74098 1.6088 9.21219 1.6088 9.79178V15.1118H0.469809C0.211738 15.1118 0.0032959 15.3097 0.0032959 15.5547C0.0032959 15.7997 0.211738 15.9976 0.469809 15.9976H17.8152C18.0732 15.9976 18.2817 15.7997 18.2817 15.5547C18.2817 15.3097 18.0732 15.1118 17.8152 15.1118ZM13.4081 1.0508C13.4081 0.961272 13.485 0.888234 13.5793 0.888234H15.495C15.5893 0.888234 15.6662 0.961272 15.6662 1.0508V15.1118H13.4056V1.0508H13.4081ZM7.9762 4.51185C7.9762 4.42232 8.05313 4.34929 8.14742 4.34929H10.0631C10.1574 4.34929 10.2343 4.42232 10.2343 4.51185V15.1118H7.97372V4.51185H7.9762ZM2.5443 9.79414C2.5443 9.70461 2.62123 9.63157 2.71552 9.63157H4.6312C4.7255 9.63157 4.80242 9.70461 4.80242 9.79414V15.1141H2.54182V9.79414H2.5443Z" fill={props.stroke || "white"} stroke="none" />
            </g>
            <defs>
                <clipPath id="clip0_9501_1350">
                    <rect width="18.2857" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>;
}
export default BarGraphIcon;