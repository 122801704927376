// #region Global Imports
import merge from "lodash/merge";
import { DefaultTheme } from "styled-components";

// #endregion Global Imports
import { common } from "./common";

export const dark: DefaultTheme = merge({}, common, {
    colors: {
        primary: "#0070f3",
        secondary: "#ff4081",

        toggleBorder: "#556678",
        gradient: "linear-gradient(#091236, #1E215D)",
        background: "#20232A",
        headerBg: "#1A1C22",
        cardsBg: "#1A1C22",
        textColor: "#FFFFFF",
        dodgerBlue: "#0062CC",
    },
});
