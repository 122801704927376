import { vendorJwtPropertyName } from "@novel/shared/utils/appConstants";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";
import { getVendorAuthToken } from "@vendor-portal/utils/getVendorAuthToken";

import { IVendorUser } from "../../../../../shared/interfaces/VendorUser";
import {
    setInLocalStorage,
    unsetInLocalStorage,
} from "../../../../../shared/utils/localStorageUtils";

export interface IAuthReducerState {
    isLoading: boolean;
    authError: string | null;
    user: IVendorUser | null;
    authToken: string | null;
}

const initialState: IAuthReducerState = {
    isLoading: true,
    authError: null,
    user: null,
    authToken: getVendorAuthToken() || null,
};

export const authReducer: NovelVendorSubReducer<IAuthReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_USER": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_USER": {
            return {
                ...state,
                isLoading: false,
                authError: action.payload.errorMessage,
            };
        }

        case "LOADED_USER": {
            const jwtToken = action.payload.authToken;
            if (jwtToken) {
                setInLocalStorage(vendorJwtPropertyName, jwtToken);
            } else {
                unsetInLocalStorage(vendorJwtPropertyName);
            }

            return {
                ...state,
                isLoading: false,
                authToken: action.payload.authToken,
                user: action.payload.user,
            };
        }

        case "LOG_OUT": {
            return {
                ...state,
                isLoading: false,
                authToken: null,
                user: null,
            };
        }

        default: {
            return state;
        }
    }
};

export function useIsSuperUserSelector(): boolean {
    return useNovelVendorSelector((state) => !!state.auth.user?.isSuperUser);
}
