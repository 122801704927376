import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";

import { INovelState } from "./reducers";
import {
    NovelVendorAction,
    NovelVendorActionActionType,
    GeneralNovelVendorDispatch,
    NovelVendorStore,
} from "./vendorTypedRedux";
import { capitalize } from "lodash";

export function useNovelVendorStore(): NovelVendorStore {
    return useStore<INovelState, NovelVendorAction<NovelVendorActionActionType>>();
}

export function useNovelVendorDispatch(): GeneralNovelVendorDispatch {
    return useDispatch<GeneralNovelVendorDispatch>();
}

export function useNovelVendorSelector<TSelected>(
    selector: (state: INovelState) => TSelected,
    equalityFn: (left: TSelected, right: TSelected) => boolean = shallowEqual, // we default to shallow equal even though redux does not now
): TSelected {
    return useSelector<INovelState, TSelected>(useCallback(selector, [selector]), equalityFn);
}

export function useUserDisplayName(): string {
    const user = useNovelVendorSelector((state) => state.auth.user);
    return useMemo(
        () =>
            [capitalize(user?.firstName || ""), capitalize(user?.lastName || "")].join(" ") ||
            user?.email ||
            "",
        [user?.firstName, user?.lastName, user?.email],
    );
}
