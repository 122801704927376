import { IPaginatedCollectionClaims } from "@novel/shared/interfaces/TokenCollectionClaim";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITokenClaimsReducerState extends IPaginatedCollectionClaims {
    isLoading: boolean;
}

const initialState: ITokenClaimsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    tokenCollectionClaims: [],
};

export const tokenClaimsReducer: NovelVendorSubReducer<ITokenClaimsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TOKEN_CLAIMS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_TOKEN_CLAIMS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TOKEN_CLAIMS": {
            return {
                ...state,
                totalCount: action.payload.paginatedTokenClaims.totalCount,
                pageSize: action.payload.paginatedTokenClaims.pageSize,
                pageNumber: action.payload.paginatedTokenClaims.pageNumber,
                totalPages: action.payload.paginatedTokenClaims.totalPages,
                tokenCollectionClaims: action.payload.paginatedTokenClaims.tokenCollectionClaims,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
