import { VENDOR_HEADERS, SHOPIFY_HEADERS } from "@novel/shared/utils/appConstants";
import { apiPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";

import { RestReq, TypedBodyRequestInit, TypedBodyRequestNoReqBodyInit } from "../utils/restReq";
import { paths } from "../__generated__/GeneratedVendorRouteTypes";
import type {
    DeleteParams,
    DeleteQueryParams,
    DeleteResponse,
    GetParams,
    GetQueryParams,
    GetResponse,
    PostParams,
    PostQueryParams,
    PostRequest,
    PostResponse,
    PatchParams,
    PatchQueryParams,
    PatchRequest,
    PatchResponse,
    PutParams,
    PutQueryParams,
    PutRequest,
    PutResponse,
} from "./Http";

/*
 * Typed API
 */
export class TypedFrontendVendorApi extends RestReq {
    private getShopifySessionToken?: () => Promise<string | undefined>;

    constructor(
        getIsImpersonating: () => boolean,
        getAuthToken: () => string | undefined,
        getOrgDomain: () => string | undefined,
        getShopifySessionToken?: () => Promise<string | undefined>,
    ) {
        super(apiPrefixedHostWithProtocol, {}, [
            (endpoint, requestConfig) => {
                const isImpersonating = getIsImpersonating();
                const authToken = getAuthToken();
                const orgDomain = getOrgDomain();
                return {
                    ...requestConfig,
                    headers: {
                        ...(authToken
                            ? {
                                  [VENDOR_HEADERS.AUTHORIZATION]: `Bearer ${authToken}`,
                              }
                            : {}),
                        ...(orgDomain ? { [VENDOR_HEADERS.ORG_DOMAIN]: orgDomain } : {}),
                        ...(isImpersonating
                            ? {
                                  [VENDOR_HEADERS.IMPERSONATE]: `${isImpersonating}`,
                              }
                            : {}),
                        ...requestConfig.headers,
                    },
                };
            },
        ]);

        // this handles when localStorage is disabled
        this.getShopifySessionToken = getShopifySessionToken;
    }

    async getReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestNoReqBodyInit<
            GetParams<Endpoint>,
            GetQueryParams<Endpoint>
        > = {},
    ) {
        return super.getReq<GetResponse<Endpoint>, GetParams<Endpoint>, GetQueryParams<Endpoint>>(
            endpoint,
            {
                ...requestConfig,
                headers: {
                    ...requestConfig.headers,
                    ...(this.getShopifySessionToken
                        ? {
                              [SHOPIFY_HEADERS.SHOPIFY_SESSION_TOKEN]:
                                  await this.getShopifySessionToken(),
                          }
                        : {}),
                },
            },
        );
    }

    async postReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PostRequest<Endpoint>,
            PostParams<Endpoint>,
            PostQueryParams<Endpoint>
        > = {},
    ) {
        return super.postReq<
            PostRequest<Endpoint>,
            PostResponse<Endpoint>,
            PostParams<Endpoint>,
            PostQueryParams<Endpoint>
        >(endpoint, {
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                ...(this.getShopifySessionToken
                    ? {
                          [SHOPIFY_HEADERS.SHOPIFY_SESSION_TOKEN]:
                              await this.getShopifySessionToken(),
                      }
                    : {}),
            },
        });
    }

    async patchReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PatchRequest<Endpoint>,
            PatchParams<Endpoint>,
            PatchQueryParams<Endpoint>
        > = {},
    ) {
        return super.patchReq<
            PatchRequest<Endpoint>,
            PatchResponse<Endpoint>,
            PatchParams<Endpoint>,
            PatchQueryParams<Endpoint>
        >(endpoint, {
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                ...(this.getShopifySessionToken
                    ? {
                          [SHOPIFY_HEADERS.SHOPIFY_SESSION_TOKEN]:
                              await this.getShopifySessionToken(),
                      }
                    : {}),
            },
        });
    }

    async putReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestInit<
            PutRequest<Endpoint>,
            PutParams<Endpoint>,
            PutQueryParams<Endpoint>
        > = {},
    ) {
        return super.putReq<
            PutRequest<Endpoint>,
            PutResponse<Endpoint>,
            PutParams<Endpoint>,
            PutQueryParams<Endpoint>
        >(endpoint, {
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                ...(this.getShopifySessionToken
                    ? {
                          [SHOPIFY_HEADERS.SHOPIFY_SESSION_TOKEN]:
                              await this.getShopifySessionToken(),
                      }
                    : {}),
            },
        });
    }

    async deleteReq<Endpoint extends keyof paths>(
        endpoint: Endpoint,
        requestConfig: TypedBodyRequestNoReqBodyInit<
            DeleteParams<Endpoint>,
            DeleteQueryParams<Endpoint>
        > = {},
    ) {
        return super.deleteReq<
            DeleteResponse<Endpoint>,
            DeleteParams<Endpoint>,
            DeleteQueryParams<Endpoint>
        >(endpoint, {
            ...requestConfig,
            headers: {
                ...requestConfig.headers,
                ...(this.getShopifySessionToken
                    ? {
                          [SHOPIFY_HEADERS.SHOPIFY_SESSION_TOKEN]:
                              await this.getShopifySessionToken(),
                      }
                    : {}),
            },
        });
    }
}
