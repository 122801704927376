const GID_TYPE_REGEXP = /^gid:\/\/[\w-]+\/([\w-]+)\//;

export type ShopifyGidNamespace =
    | "Customer"
    | "MailingAddress"
    | "GiftCard"
    | "BulkOperation"
    | "Collection"
    | "InventoryItem"
    | "LineItem"
    | "Product"
    | "ProductVariant"
    | "PriceRule"
    | "Order"
    | "OrderTransaction"
    | "FulfillmentOrder"
    | "SubscriptionLine"
    | "SubscriptionContract"
    | "SubscriptionDraft"
    | "SellingPlan"
    | "SellingPlanGroup"
    | "CustomerPaymentMethod"
    | "BillingAttempt"
    | "CustomerVisit"
    | "MarketingEvent"
    | "DeliveryProfile"
    | "OnlineStoreTheme"
    | "Location"
    | "AppSubscription"
    | "AppSubscriptionLineItem"
    | "AppUsageRecord";

export function isShopifyGid(namespace: ShopifyGidNamespace, id: string): boolean {
    const prefix = `gid://shopify/${namespace}`;
    return `${id}`.includes(prefix);
}

export function ensureShopifyGid(namespace: ShopifyGidNamespace, id: number | string): string {
    const prefix = `gid://shopify/${namespace}`;
    const startsWithPrefix = `${id}`.includes(prefix);

    let idWithQueryRemoved = `${id}`;
    const indexOfQuestionMark = idWithQueryRemoved.indexOf("?");
    if (indexOfQuestionMark !== -1) {
        idWithQueryRemoved = idWithQueryRemoved.slice(0, indexOfQuestionMark);
    }

    if (startsWithPrefix) {
        return idWithQueryRemoved;
    } else {
        return `${prefix}/${idWithQueryRemoved}`;
    }
}

// from https://github.com/Shopify/quilt/blob/master/packages/admin-graphql-api-utilities/src/index.ts
export function parseGidType(gid: string): string {
    const matches = GID_TYPE_REGEXP.exec(gid);

    if (matches && matches[1] !== undefined) {
        return matches[1];
    }
    throw new Error(`Invalid gid: ${gid}`);
}

// needed for some REST APIs
export function shopifyGidToNumber(gid: string | number): number {
    return typeof gid === "number" ? gid : Number(gid.split("/").slice(-1)[0]);
}

// for displaying in UI
export function viewShopifyId(gid: string | number | undefined): string {
    return `${!gid ? "--" : shopifyGidToNumber(gid.toString())}`;
}
