import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadEligibleTiers =
    (): NovelThunkVendorAction<"LOADING_TIERS" | "ERROR_LOADING_TIERS" | "LOADED_TIERS"> =>
    async (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/tier"),
            () =>
                dispatch({
                    type: "LOADING_TIERS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_TIERS",
                    payload: undefined,
                }),
            ({ data }) =>
                dispatch({
                    type: "LOADED_TIERS",
                    payload: {
                        tiers: data,
                    },
                }),
        );
