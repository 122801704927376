import { IOrgTokenCollectionsRes } from "@novel/shared/interfaces/OrgTokenCollection";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITokenCollectionsReducerState extends IOrgTokenCollectionsRes {
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: ITokenCollectionsReducerState = {
    isLoading: true,
    initialLoadHasBeenTriggered: false,
    data: [],
};

export const tokenCollectionsReducer: NovelVendorSubReducer<ITokenCollectionsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_TOKEN_COLLECTIONS": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_TOKEN_COLLECTIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TOKEN_COLLECTIONS": {
            return {
                ...state,
                data: action.payload.orgTokenCollectionsRes.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
