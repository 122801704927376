import { IInCommPurseWithRedemptionsAndOffersAndCustomers } from "@novel/shared/interfaces/RetailNetwork";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IRetailRedemptionReducerState {
    isLoading: boolean;
    data: IInCommPurseWithRedemptionsAndOffersAndCustomers[];
}

const initialState: IRetailRedemptionReducerState = {
    isLoading: true,
    data: [],
};

export const retailRedemptionReducer: NovelVendorSubReducer<IRetailRedemptionReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_RETAIL_REDEMPTIONS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_RETAIL_REDEMPTIONS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_RETAIL_REDEMPTIONS": {
            return {
                ...state,
                data: action.payload.redemptions,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
