import { IPaginatedRewardsSourceTags } from "@novel/shared/interfaces/RewardsSourceTag";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IRewardsSourceTagsReducerState extends IPaginatedRewardsSourceTags {
    isLoading: boolean;
}

const initialState: IRewardsSourceTagsReducerState = {
    isLoading: true,
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    totalPages: 0,
    data: [],
};

export const rewardsSourceTagsReducer: NovelVendorSubReducer<IRewardsSourceTagsReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_REWARDS_SOURCE_TAGS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_REWARDS_SOURCE_TAGS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_REWARDS_SOURCE_TAGS": {
            return {
                ...state,
                totalCount: action.payload.paginatedRewardsSourceTags.totalCount,
                pageSize: action.payload.paginatedRewardsSourceTags.pageSize,
                pageNumber: action.payload.paginatedRewardsSourceTags.pageNumber,
                totalPages: action.payload.paginatedRewardsSourceTags.totalPages,
                data: action.payload.paginatedRewardsSourceTags.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
