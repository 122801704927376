import {
    ILoadPaginatedAction,
    serializePaginatedQueryValues,
} from "@novel/shared/utils/pagination";
import { asyncDispatch } from "@vendor-portal/redux/utils/asyncDispatch";
import { NovelThunkVendorAction } from "@vendor-portal/redux/vendorTypedRedux";
import { typedFrontendVendorApi } from "@vendor-portal/utils/typedFrontendVendorApi";

export const loadOrganizationSummaries =
    ({
        queryParams,
    }: ILoadPaginatedAction): NovelThunkVendorAction<
        "LOADING_ORGANIZATIONS" | "ERROR_LOADING_ORGANIZATIONS" | "LOADED_ORGANIZATIONS"
    > =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/organization/summary", {
                query: serializePaginatedQueryValues(queryParams),
            }),
            () =>
                dispatch({
                    type: "LOADING_ORGANIZATIONS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_ORGANIZATIONS",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (organizations) =>
                dispatch({
                    type: "LOADED_ORGANIZATIONS",
                    payload: {
                        organizations,
                    },
                }),
        );

export const loadOrganizationDropDown =
    (): NovelThunkVendorAction<
        | "LOADING_ORGANIZATIONS_DROPDOWN"
        | "ERROR_LOADING_ORGANIZATIONS_DROPDOWN"
        | "LOADED_ORGANIZATIONS_DROPDOWN"
    > =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/organization"),
            () =>
                dispatch({
                    type: "LOADING_ORGANIZATIONS_DROPDOWN",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_ORGANIZATIONS_DROPDOWN",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (organizations) =>
                dispatch({
                    type: "LOADED_ORGANIZATIONS_DROPDOWN",
                    payload: {
                        organizations,
                    },
                }),
        );

export const loadIntegrations =
    (): NovelThunkVendorAction<
        "LOADING_INTEGRATIONS" | "ERROR_LOADING_INTEGRATIONS" | "LOADED_INTEGRATIONS"
    > =>
    (dispatch) =>
        asyncDispatch(
            typedFrontendVendorApi.getReq("/api/vendor/integrations"),
            () =>
                dispatch({
                    type: "LOADING_INTEGRATIONS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_INTEGRATIONS",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                }),
            (integrations) =>
                dispatch({
                    type: "LOADED_INTEGRATIONS",
                    payload: {
                        integrations,
                    },
                }),
        );
