import { IOrganization } from "@novel/shared/interfaces/Organization";

export function orgDomainMatch(org: IOrganization, domain: string): boolean {
    if (org.orgHeadlessDomain && org.orgHeadlessDomain === domain) {
        return true;
    }

    if (org.orgDomain && org.orgDomain === domain) {
        return true;
    }

    if (
        org.shopifyShopData &&
        (org.shopifyShopData?.domain === domain || org.shopifyShopData?.myShopifyDomain === domain)
    ) {
        return true;
    }

    return false;
}
