import { IPriceRulesRes } from "@novel/shared/interfaces/PriceRules";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPriceRulesReducerState extends IPriceRulesRes {
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: IPriceRulesReducerState = {
    isLoading: true,
    initialLoadHasBeenTriggered: false,
    data: [],
};

export const priceRulesReducer: NovelVendorSubReducer<IPriceRulesReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_PRICE_RULES": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_PRICE_RULES": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_PRICE_RULES": {
            return {
                ...state,
                data: action.payload.priceRulesRes.data,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
