import { IRewardsTierWithCustomerCount } from "@novel/shared/interfaces/RewardsConfig";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface ITiersState {
    data: IRewardsTierWithCustomerCount[];
    isLoading: boolean;
    initialLoadHasBeenTriggered: boolean;
}

const initialState: ITiersState = {
    data: [],
    isLoading: false,
    initialLoadHasBeenTriggered: false,
};

export const tiersReducer: NovelVendorSubReducer<ITiersState> = (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_TIERS": {
            return {
                ...state,
                isLoading: true,
                initialLoadHasBeenTriggered: true,
            };
        }

        case "ERROR_LOADING_TIERS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_TIERS": {
            return {
                ...state,
                data: action.payload.tiers,
                isLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};
